import React from 'react'

import { Card } from 'react-bootstrap';
import SEO from '../../../common/SEO';
import { FiArrowRight } from 'react-icons/fi';
import HeaderOne from '../../../common/header/HeaderOne';
import Layout from '../../../common/Layout';
import { Link } from 'react-router-dom';
import Separator from '../../separator/Separator';
import ScrollAnimation from 'react-animate-on-scroll';
import SectionTitle from '../../sectionTitle/SectionTitle';
import Typed from 'react-typed';
const  BusinessSolutions=()=> {
  return (
    <>
        <SEO title={"Digital Transformation Strength"}/>
        <main className="page-wrapper">
            <HeaderOne btnStyle="btn-small btn-icon round" HeaderSTyle="header-transparent"/>
                <Layout>
                <div className="slider-area slider-style-5 bg-overlay-solid height-850 bg_image" data-black-overlay="3" style={{backgroundImage: `url(${process.env.PUBLIC_URL}/images/business/bg-image-1.jpg)`}}>
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="inner text-start">
                                            <h4 className="subtitle">MARKO</h4>
                                            <h1 className="title theme-gradient display-two">
                                            <Typed
                                              strings={[
                                                "Business <br/>Solutions",
                                            ]}
                                            typeSpeed={80}
                                            backSpeed={5}
                                            backDelay={1000}
                                            loop
                                            />
                                            </h1>
                                            <p className="description">Streamline your operations and achieve business success <br/>with our tailored solutions.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                    </div>
                {/* End Slider area  */} 
                <Separator />
                <div className="rn-company-mission-are rn-section-gap">
                            <div className="container">
                                <div className="row row--30">
                                    <div className="col-lg-7">
                                        <div className="mission-title">
                                            <h4 className="title">Business Solutions for Success {" "}
                                            </h4>
                                            <p className="description">Innovative business solutions for accelerated success. Tailored to your unique needs, our solutions include process optimization, technology integration, strategic planning, and performance management.</p>
                                            <p className="description">Stay ahead with our industry-leading solutions. Gain a competitive edge through cutting-edge solutions that streamline operations, drive growth, and keep you ahead of the competition.</p>
                                            <p className="description">Tangible results with our results-driven solutions. We are committed to delivering measurable outcomes, from improved efficiency to increased revenue, reduced costs, and enhanced customer satisfaction.</p>
                                            <p className="description">Customized solutions for your unique business. Partner with us for tailored solutions that meet your specific requirements, with a team of experts who understand your challenges and opportunities.</p>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 mt_md--30 mt_sm--30">
                                        <h4 className="title"> Our Impact</h4>
                                        <Card className='card bg-transparent' style={{ width: '40rem' }}>
                                            <Card.Img  src="./images/business/impact-2.jpg" /> <br/>
                                        </Card>
                                            <h5>Achieve Business Excellence </h5>
                                            <p className="description">Our innovative business solutions offer process optimization, productivity enhancement, and operational excellence. Partner with us to unlock your business potential and stay ahead in today's competitive landscape.</p>
                                            <Link className="btn-read-more" to="#"><span>View More</span></Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Separator/>
                        <div className='container'>
                            <div className='row'>
                                <div className='col-lg-12'>
                                    <div className="text-center">
                                        <SectionTitle
                                            textAlign = "text-center"
                                            radiusRounded = ""
                                            title = "Our Solutions"
                                            description = "Our solutions empower your business with process optimization, productivity enhancement, and innovation. <br/>Partner with us to unlock your organization's full potential and achieve success in today's competitive landscape."
                                        />                                        
                                    </div>
                                    {/* <SlipThree/> */}
                                    <div className="rn-splite-style bg-color-blackest">
                                        <div className="split-wrapper">
                                            <div className="row no-gutters radius-10 align-items-center">
                                                <div className="col-lg-12 col-xl-6 col-12">
                                                    <div className="thumbnail">
                                                        <img src="./images/business/bg-image-3.jpg" alt="split Images" />
                                                    </div>
                                                </div>
                                                <div className="col-lg-12 col-xl-6 col-12">
                                                    <div className="split-inner">
                                                        <ScrollAnimation 
                                                        animateIn="fadeInUp"
                                                        animateOut="fadeInOut"
                                                        animateOnce={true}>
                                                        <h4 className="">Unlock your business potential with our innovative solutions</h4>
                                                        </ScrollAnimation>
                                                        <ScrollAnimation 
                                                        animateIn="fadeInUp"
                                                        animateOut="fadeInOut"
                                                        animateOnce={true}>
                                                        <p className="description">Unleash your business potential with our innovative solutions. Optimize your processes, drive efficiency, and achieve tangible results with our strategic approach and customized solutions. Partner with us to unlock new opportunities for growth and success.</p>
                                                        </ScrollAnimation>
                                                        <Link className="btn-default btn-icon" to="#"> Learn More<i className="icon"><FiArrowRight/></i></Link>                                                        
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Separator />                        
                        <div className='container'>
                            <div className='row'>
                                <div className='col-lg-12'>
                                    <div className="text-center">
                                        <SectionTitle
                                            textAlign = "text-center"
                                            radiusRounded = ""
                                            title = "Why Marko"
                                            description = "Choose us for our expertise, customer-centric approach, innovation, results-driven mindset, collaborative approach, customization<br/> options, and proven track record of delivering high-quality solutions. Partner with us to achieve operational excellence,<br/> drive business success, and experience exceptional service and tangible results for your business."
                                        />
                                        <div className="rn-blog-area rn-section-gap">
                                            <div className="content">
                                                <div className="row mt_dec--30">
                                                    <div className="col-sm rn-card m-4">
                                                        <div className="card bg-transparent border-0" >
                                                            <div className="card-body">
                                                                <ScrollAnimation 
                                                                    animateIn="fadeInUp"
                                                                    animateOut="fadeInOut"
                                                                    animateOnce={true}><br />
                                                                    <h5 className="">Strategic Planning</h5>
                                                                    </ScrollAnimation>
                                                                    <ScrollAnimation 
                                                                    animateIn="fadeInUp"
                                                                    animateOut="fadeInOut"
                                                                    animateOnce={true}>
                                                                    <p className="description">We assist businesses in developing comprehensive strategic plans, setting clear objectives, and defining actionable strategies to achieve their long-term goals.</p>
                                                                </ScrollAnimation>
                                                                <Link className="btn-read-more" to="#"><span>Learn More</span></Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm rn-card  m-4">
                                                        <div className="card bg-transparent border-0" >
                                                            <div className="card-body">
                                                            <ScrollAnimation 
                                                                animateIn="fadeInUp"
                                                                animateOut="fadeInOut"
                                                                animateOnce={true}><br />
                                                                <h5 className="">Performance Management</h5>
                                                                </ScrollAnimation>
                                                                <ScrollAnimation 
                                                                animateIn="fadeInUp"
                                                                animateOut="fadeInOut"
                                                                animateOnce={true}>
                                                                <p className="description">We provide performance management solutions that enable businesses to measure, monitor, and improve their performance, ensuring they stay on track to achieve their business objectives.</p>
                                                                </ScrollAnimation>
                                                                <Link className="btn-read-more" to="#"><span>Learn More</span></Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className=" col-sm rn-card  m-4">
                                                        <div class="card bg-transparent border-0" >
                                                            <div class="card-body">
                                                            <ScrollAnimation 
                                                                animateIn="fadeInUp"
                                                                animateOut="fadeInOut"
                                                                animateOnce={true}><br />
                                                                <h5 className="">Organizational Development</h5>
                                                                </ScrollAnimation>
                                                                <ScrollAnimation 
                                                                animateIn="fadeInUp"
                                                                animateOut="fadeInOut"
                                                                animateOnce={true}>
                                                                <p className="description">We offer organizational development solutions to help businesses build a high-performance culture, develop leadership capabilities, and enhance employee engagement and productivity.</p>
                                                                </ScrollAnimation>
                                                                <Link className="btn-read-more" to="#"><span>Learn More</span></Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    
                                                </div>
                                            </div>
                                            <div className="content pt-5">
                                                <div className="row mt_dec--30">
                                                    <div className="col-sm rn-card m-4">
                                                        <div className="card bg-transparent border-0" >
                                                            <div className="card-body">
                                                            <ScrollAnimation 
                                                                animateIn="fadeInUp"
                                                                animateOut="fadeInOut"
                                                                animateOnce={true}>
                                                                <br />
                                                                <h5 className="">Market Research and Analysis</h5>
                                                                </ScrollAnimation>
                                                                <ScrollAnimation 
                                                                animateIn="fadeInUp"
                                                                animateOut="fadeInOut"
                                                                animateOnce={true}>
                                                                <p className="description">We conduct in-depth market research and analysis to help businesses understand market trends, customer preferences, and competitive landscapes, enabling them to make informed strategic decisions.</p>
                                                                </ScrollAnimation>
                                                                <Link className="btn-read-more" to="#"><span>Learn More</span></Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm rn-card  m-4">
                                                        <div className="card bg-transparent border-0" >
                                                            <div className="card-body">
                                                            <ScrollAnimation 
                                                                animateIn="fadeInUp"
                                                                animateOut="fadeInOut"
                                                                animateOnce={true}><br />
                                                                <h5 className="">Marketing and Sales Strategies</h5>
                                                                </ScrollAnimation>
                                                                <ScrollAnimation 
                                                                animateIn="fadeInUp"
                                                                animateOut="fadeInOut"
                                                                animateOnce={true}>
                                                                <p className="description">We develop and implement effective marketing and sales strategies to help businesses attract, engage, and retain customers, and drive revenue growth.</p>
                                                                </ScrollAnimation>
                                                                <Link className="btn-read-more" to="#"><span>View More</span></Link>
                                                            </div>
                                                        </div>
                                                    </div>                                               
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>                     
                </Layout>
        </main>
    </>
  )
}

export default BusinessSolutions;