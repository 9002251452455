import React, { useState } from "react";
import FsLightbox from "fslightbox-react";
import { FiPlay } from "react-icons/fi";

const PopupContent =  {
    popupLink: [ "https://youtu.be/UUtj2V3BpIw"],
}
function VideoTwo({ imagename}) {
    

    const [toggler, setToggler] = useState(false);
    return (
        <>
            <div className="video-popup icon-center">
                <div className="overlay-content">
                    <div className="thumbnail">
                        <img className="radius-small" src={`${imagename}`} alt="Marko Image" />
                    </div>
                    <div className="video-icon">
                        <button className="btn-default rounded-player" onClick={ () => setToggler(!toggler) }><span><FiPlay/></span></button>
                    </div>
                </div>
            </div>
            <div className="video-lightbox-wrapper">
                <FsLightbox 
                toggler={ toggler } 
                sources={PopupContent.popupLink} />
            </div>
        </>
    )
}
export default VideoTwo;