import React from 'react';
import { FiFacebook, FiTwitter, FiInstagram, FiLinkedin, FiPhone } from "react-icons/fi";
import { Link } from 'react-router-dom'

const HeaderTopBar = () => {
    return (
        <div className="header-top-bar">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-4 col-md-12 col-12">
                        <div className="header-left">
                            {/* <p><a href="#link">Marko Digital Service<FiChevronRight /></a></p> */}
                        </div>
                    </div>
                    <div className="col-lg-8 col-md-12 col-12">
                        <div className="header-right">
                            <div className="address-content">
                                {/* <p><FiMapPin /><span>Raipur, IND</span></p> */}
                                <p><FiPhone /><span><a href="#">+91 626 0515 523</a></span></p>
                            </div>
                            <div className="social-icon-wrapper">
                                <ul className="social-icon social-default icon-naked">
                                    <li><Link to="https://www.facebook.com/marko.software"><FiFacebook /></Link></li>
                                    <li><Link to="https://www.twitter.com"><FiTwitter /></Link></li>
                                    <li><Link to="https://www.instagram.com/itmarko.in"><FiInstagram /></Link></li>
                                    <li><Link to="https://www.linkedin.com/company/marko-in"><FiLinkedin /></Link></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HeaderTopBar;
