import React from 'react'

import { Card } from 'react-bootstrap';
import SEO from '../../../common/SEO';
import { FiArrowRight } from 'react-icons/fi';
import HeaderOne from '../../../common/header/HeaderOne';
import Layout from '../../../common/Layout';
import { Link } from 'react-router-dom';
import Separator from '../../separator/Separator';
import ScrollAnimation from 'react-animate-on-scroll';
import SectionTitle from '../../sectionTitle/SectionTitle';
import Typed from 'react-typed';
const  Cybersecurity=()=> {
  return (
    <>
        <SEO title={"Cybersecurity Services"}/>
        <main className="page-wrapper">
            <HeaderOne btnStyle="btn-small btn-icon round" HeaderSTyle="header-transparent"/>
                <Layout>
                <div className="slider-area slider-style-5 bg-overlay-solid height-850 bg_image" data-black-overlay="3" style={{backgroundImage: `url(${process.env.PUBLIC_URL}/images/bg/bg-image-3.jpg)`}}>
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="inner text-start">
                                            <h4 className="subtitle">MARKO</h4>
                                            <h1 className="title theme-gradient display-two">
                                            <Typed
                                              strings={[
                                                "Cybersecurity",
                                            ]}
                                            typeSpeed={80}
                                            backSpeed={5}
                                            backDelay={1000}
                                            loop
                                            />
                                            </h1>
                                            <p className="description">Cybersecurity as competitive advantage in a world of uncertainty</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                    </div>
                {/* End Slider area  */} 
                <Separator />
                <div className="rn-company-mission-are rn-section-gap">
                            <div className="container">
                                <div className="row row--30">
                                    <div className="col-lg-7">
                                        <div className="mission-title">
                                            <h4 className="title">Mapping risk and steering through transformation {" "}
                                            </h4>
                                            <p className="description">Risk management is essential for successful organizational transformation. It involves identifying, assessing, and mitigating risks related to technology, operations, compliance, and change resistance. Proactive risk management minimizes disruptions and maximizes transformation outcomes.</p>
                                            <p className="description">Mapping risks in transformation is crucial to identify and mitigate potential risks related to technology, processes, organizational changes, and cultural shifts. By assessing and prioritizing risks, organizations can develop effective risk mitigation strategies, ensuring a successful transformation journey.</p>
                                            <p className="description">Effective transformation requires proactive risk management and continuous monitoring to identify and mitigate risks in a timely manner. Collaboration among stakeholders, plan revisions, resource realignment, and adaptive strategies are critical in steering through the transformation journey. By staying agile and resilient in managing risks, organizations can achieve successful outcomes.</p>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 mt_md--30 mt_sm--30">
                                        <h4 className="title"> Our Impact</h4>
                                        <Card className='card bg-transparent' style={{ width: '40rem' }}>
                                            <Card.Img  src="./images/bg/cyb-2.jpg" /> <br/>
                                        </Card>
                                            <h5>Cybersecurity journey </h5>
                                            <p className="description">Guarding Your Digital Assets: A Comprehensive Cybersecurity Journey for Resilient Defense in the Digital Era</p>
                                            <Link className="btn-read-more" to="#"><span>View More</span></Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Separator/>
                        <div className='container'>
                            <div className='row'>
                                <div className='col-lg-12'>
                                    <div className="text-center">
                                        <SectionTitle
                                            textAlign = "text-center"
                                            radiusRounded = ""
                                            title = "Our Solutions"
                                            description = "Our company offers a cutting-edge cybersecurity solution that is tailored to meet the evolving challenges of <br/> the digital landscape. Our solution is built on innovative technologies and industry best practices, providing<br/> comprehensive protection for your digital assets against a wide range of cyber threats."
                                        />                                        
                                    </div>
                                    {/* <SlipThree/> */}
                                    <div className="rn-splite-style bg-color-blackest">
                                        <div className="split-wrapper">
                                            <div className="row no-gutters radius-10 align-items-center">
                                                <div className="col-lg-12 col-xl-6 col-12">
                                                    <div className="thumbnail">
                                                        <img src="./images/bg/cyb-3.jpg" alt="split Images" />
                                                    </div>
                                                </div>
                                                <div className="col-lg-12 col-xl-6 col-12">
                                                    <div className="split-inner">
                                                        <ScrollAnimation 
                                                        animateIn="fadeInUp"
                                                        animateOut="fadeInOut"
                                                        animateOnce={true}>
                                                        <h4 className="">Cybersecurity</h4>
                                                        </ScrollAnimation>
                                                        <ScrollAnimation 
                                                        animateIn="fadeInUp"
                                                        animateOut="fadeInOut"
                                                        animateOnce={true}>
                                                        <p className="description">Another key aspect of our cybersecurity solution is its holistic approach to defense. We understand that cybersecurity is not just about technology, but also about people and processes. Our solution includes comprehensive training and awareness programs for employees, as well as robust policies and procedures to ensure secure practices across the organization. This multi-layered approach minimizes vulnerabilities and strengthens the overall security posture of your organization.</p>
                                                        </ScrollAnimation>
                                                        <Link className="btn-default btn-icon" to="#"> Learn More<i className="icon"><FiArrowRight/></i></Link>                                                        
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Separator />                        
                        <div className='container'>
                            <div className='row'>
                                <div className='col-lg-12'>
                                    <div className="text-center">
                                        <SectionTitle
                                            textAlign = "text-center"
                                            radiusRounded = ""
                                            title = "Why Marko"
                                            description = "Our cybersecurity solution utilizes cutting-edge technologies, such as machine learning, behavioral analytics, and threat intelligence, to provide comprehensive protection against a wide range of cyber threats. Our solution is constantly updated to stay ahead of evolving threats, ensuring the highest level of security for your digital assets."
                                        />
                                        <div className="rn-blog-area rn-section-gap">
                                            <div className="content">
                                                <div className="row mt_dec--30">
                                                    <div className="col-sm rn-card m-4">
                                                        <div className="card bg-transparent border-0" >
                                                            <div className="card-body">
                                                                <ScrollAnimation 
                                                                    animateIn="fadeInUp"
                                                                    animateOut="fadeInOut"
                                                                    animateOnce={true}><br />
                                                                    <h5 className="">Threat Intelligence Platforms</h5>
                                                                    </ScrollAnimation>
                                                                    <ScrollAnimation 
                                                                    animateIn="fadeInUp"
                                                                    animateOut="fadeInOut"
                                                                    animateOnce={true}>
                                                                    <p className="description">These platforms leverage big data, machine learning, and artificial intelligence to collect, analyze, and interpret threat data from various sources. They provide real-time insights and actionable intelligence to help organizations identify and respond to emerging threats proactively.</p>
                                                                </ScrollAnimation>
                                                                <Link className="btn-read-more" to="#"><span>Learn More</span></Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm rn-card  m-4">
                                                        <div className="card bg-transparent border-0" >
                                                            <div className="card-body">
                                                            <ScrollAnimation 
                                                                animateIn="fadeInUp"
                                                                animateOut="fadeInOut"
                                                                animateOnce={true}><br />
                                                                <h5 className="">Zero Trust Architecture</h5>
                                                                </ScrollAnimation>
                                                                <ScrollAnimation 
                                                                animateIn="fadeInUp"
                                                                animateOut="fadeInOut"
                                                                animateOnce={true}>
                                                                <p className="description">This modern approach to cybersecurity that challenges the traditional "trust but verify" model. It assumes that no user or device can be trusted by default, even if they are within the organization's perimeter. Zero Trust Architecture implements strict access controls, multi-factor authentication, and continuous monitoring to ensure that only authorized users and devices can access sensitive resources.</p>
                                                                </ScrollAnimation>
                                                                <Link className="btn-read-more" to="#"><span>Learn More</span></Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className=" col-sm rn-card  m-4">
                                                        <div class="card bg-transparent border-0" >
                                                            <div class="card-body">
                                                            <ScrollAnimation 
                                                                animateIn="fadeInUp"
                                                                animateOut="fadeInOut"
                                                                animateOnce={true}><br />
                                                                <h5 className="">Endpoint Detection and Response (EDR)</h5>
                                                                </ScrollAnimation>
                                                                <ScrollAnimation 
                                                                animateIn="fadeInUp"
                                                                animateOut="fadeInOut"
                                                                animateOnce={true}>
                                                                <p className="description">EDR solutions monitor endpoints, such as laptops, desktops, and servers, for signs of malicious activity. They use behavioral analytics, machine learning, and threat intelligence to detect and respond to advanced threats that may evade traditional antivirus solutions. EDR solutions provide real-time visibility into endpoint activity and enable rapid response to potential security incidents.</p>
                                                                </ScrollAnimation>
                                                                <Link className="btn-read-more" to="#"><span>Learn More</span></Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    
                                                </div>
                                            </div>
                                            <div className="content pt-5">
                                                <div className="row mt_dec--30">
                                                    <div className="col-sm rn-card m-4">
                                                        <div className="card bg-transparent border-0" >
                                                            <div className="card-body">
                                                            <ScrollAnimation 
                                                                animateIn="fadeInUp"
                                                                animateOut="fadeInOut"
                                                                animateOnce={true}>
                                                                <br />
                                                                <h5 className="">Cloud Security Solutions</h5>
                                                                </ScrollAnimation>
                                                                <ScrollAnimation 
                                                                animateIn="fadeInUp"
                                                                animateOut="fadeInOut"
                                                                animateOnce={true}>
                                                                <p className="description">With the increasing adoption of cloud computing, modern cybersecurity solutions include robust cloud security measures. These solutions provide end-to-end security for cloud environments, including data encryption, identity and access management, and continuous monitoring of cloud resources for security threats.</p>
                                                                </ScrollAnimation>
                                                                <Link className="btn-read-more" to="#"><span>Learn More</span></Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm rn-card  m-4">
                                                        <div className="card bg-transparent border-0" >
                                                            <div className="card-body">
                                                            <ScrollAnimation 
                                                                animateIn="fadeInUp"
                                                                animateOut="fadeInOut"
                                                                animateOnce={true}><br />
                                                                <h5 className="">User and Entity Behavior Analytics (UEBA)</h5>
                                                                </ScrollAnimation>
                                                                <ScrollAnimation 
                                                                animateIn="fadeInUp"
                                                                animateOut="fadeInOut"
                                                                animateOnce={true}>
                                                                <p className="description">UEBA solutions analyze the behavior of users and entities within an organization's network to detect anomalies and identify potential insider threats or compromised accounts. These solutions use machine learning and advanced analytics to establish baselines of normal behavior and detect deviations that may indicate security risks.</p>
                                                                </ScrollAnimation>
                                                                <Link className="btn-read-more" to="#"><span>View More</span></Link>
                                                            </div>
                                                        </div>
                                                    </div>                                               
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>                     
                </Layout>
        </main>
    </>
  )
}

export default Cybersecurity;