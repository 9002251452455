import React from 'react';
import { FiActivity,
     FiCast,
     FiMap,
     FiTrendingUp,
     FiCloud,
     FiCommand,
    FiSettings,
    FiGrid,
    FiArrowUpCircle 
} from "react-icons/fi";
import { 
    AiOutlineSolution,
    AiOutlineCustomerService,
    AiOutlineAntDesign,
    AiOutlineLaptop,
    AiOutlineMacCommand,

} 
from "react-icons/ai"
import { GiProcessor,
         GiAutomaticSas,
         GiTreeBranch,
         GiCyberEye,
         GiCobweb,
         GiSpiderWeb }
         from 'react-icons/gi';
import {Link} from "react-router-dom";
import ScrollAnimation from "react-animate-on-scroll";

const ServiceList = [
    {
        icon: <GiProcessor />,
        title: 'Artificial Intelligence',
        description: 'AI solutions to transform your business. Harness the power of data and automation to drive growth.'
    },
    {
        
        icon: <FiTrendingUp />,
        title: 'Data and Analytics',
        description: 'Our data and analytics solutions help you unlock insights, make informed decisions, and drive business growth.'
    },
    {
        icon: <FiMap />,
        title: 'Experience',
        description: 'Unlock transformational business experiences with our innovative solutions and customer-centric approach.'
    },
    {
        icon: <FiCloud />,
        title: 'Cloud',
        description: 'We offer cloud solutions to help you streamline operations, enhance security, and reduce costs.'
    },
    {
        icon: <FiCast />,
        title: 'Technology',
        description: 'We provide innovative technology solutions for businesses to drive growth, efficiency and productivity.'
    },
    {
        icon: <FiSettings />,
        title: 'Application',
        description: 'We offer innovative application solutions that optimize performance and enhance customer experience for your business.'
    },
    {
        icon: <FiActivity />,
        title: 'Business Process',
        description: 'Our business process solutions optimize workflows, increase efficiency, and drive productivity for your organization.'
    },
    {
        icon: <AiOutlineSolution />,
        title: 'Business Solution',
        description: 'We provide innovative business solutions to help your organization achieve operational excellence and growth.'
    },
    {
        icon: <AiOutlineCustomerService />,
        title: 'Consulting',
        description: 'We offer consulting services to help businesses optimize their processes and achieve operational excellence.'
    },
    {
        icon: <AiOutlineAntDesign />,
        title: 'Design & Experience',
        description: 'Our innovative solutions for web, mobile, and desktop applications design and create exceptional user experiences.'
    },
    {
        icon: <GiAutomaticSas />,
        title: 'Automation',
        description: 'Automate repetitive tasks and improve efficiency with our advanced automation solutions.'
    },
    {
        icon: <GiTreeBranch />,
        title: 'Sustainability Services',
        description: 'Our sustainability services help organizations create lasting positive impact on the environment and society.'
    },
    {
        icon: <FiGrid />,
        title: 'Enterprise Solutions',
        description: 'Transform your organization with our comprehensive enterprise solutions, including ERP, CRM, SCM, and HRM.'
    },
    {
        icon: <GiCyberEye />,
        title: 'Cybersecurity',
        description: 'Our cybersecurity solutions offer comprehensive protection and risk management for businesses of all sizes.'
    },
    {
        icon: <AiOutlineLaptop />,
        title: 'Digital Marketing',
        description: 'Achieve your marketing goals with our data-driven strategies and innovative solutions for social media, SEO, and PPC.'
    },
    {
        icon: <GiCobweb />,
        title: 'Web App Development',
        description: 'Our expert team provides customized and scalable web application development solutions for businesses of all sizes.'
    },
    {
        icon: <GiSpiderWeb />,
        title: 'Web Development',
        description: 'We offer custom web development solutions that are tailored to meet the unique needs of your business.'
    },
    {
        icon: <FiCommand />,
        title: 'Andriod IOS Development',
        description: 'We specialize in developing high-quality Android and iOS mobile applications to help you reach your target audience.'
    },
    {
        icon: <FiArrowUpCircle />,
        title: 'Software Development',
        description: 'We provide full-cycle software development services to help businesses achieve their digital transformation goals.'
    },
    
]

const ServiceOne = ({textAlign, serviceStyle}) => {
    return (
        <div className="row row--15 service-wrapper">
              {ServiceList.map( (val , i) => (
                <div className="col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
                    <ScrollAnimation 
                    animateIn="fadeInUp"
                    animateOut="fadeInOut"
                    animateOnce={true}>
                        <div className={`service ${serviceStyle} ${textAlign}`}>
                            <div className="icon">
                                {val.icon}
                            </div>
                            <div className="content">
                                <h4 className="title w-600"><Link to="#service" dangerouslySetInnerHTML={{__html: val.title}}></Link></h4>
                                <p className="description b1 color-gray mb--0" dangerouslySetInnerHTML={{__html: val.description}}></p>
                            </div>
                        </div>
                    </ScrollAnimation>
                </div>
            ))}
        </div>
    )
}
export default ServiceOne;