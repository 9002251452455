import React from 'react';
import SEO from '../../../common/SEO';
import HeaderOne from '../../../common/header/HeaderOne';
import Layout from '../../../common/Layout';
import Separator from '../../separator/Separator';
import { Card } from 'react-bootstrap';
import SectionTitle from '../../sectionTitle/SectionTitle';
// import SlipThree from '../../split/SlipThree';
import { FiArrowRight } from 'react-icons/fi';
import ScrollAnimation from 'react-animate-on-scroll';
import { Link } from 'react-router-dom';
import BlogList from '../../../components/blog/itemProp/BlogList';
import BlogClassicData from '../../../data/blog/BlogList.json';
import Typed from 'react-typed';
var BlogListData = BlogClassicData.slice(0, 3);
const DigitalExperience =({ data, StyleVar })=> {
  return (
    <>
        <SEO title={"Customer Experience Services"}/>
        <main className="page-wrapper">
            <HeaderOne btnStyle="btn-small btn-icon round" HeaderSTyle="header-transparent"/>
                <Layout>
                <div className="slider-area slider-style-5 bg-overlay-solid height-850 bg_image" data-black-overlay="3" style={{backgroundImage: `url(${process.env.PUBLIC_URL}/images/exp/exp-1.jpg)`}}>
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="inner text-start">
                                            <h4 className="subtitle">MARKO</h4>
                                            <h1 className="title theme-gradient">
                                            <Typed
                                              strings={[
                                                        "Design And<br/> Experience",
                                             ]}
                                            typeSpeed={80}
                                            backSpeed={5}
                                            backDelay={1000}
                                            loop
                                            />
                                            </h1>
                                            <p className="description">Crafting Exceptional Design and Experiences: <br />Elevating the User Journey</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                    </div>
                        {/* End Slider Area  */}
                        <Separator />
                        <div className="rn-company-mission-are rn-section-gap">
                            <div className="container">
                                <div className="row row--30">
                                    <div className="col-lg-7">
                                        <div className="mission-title">
                                            <h4 className="title">Our Commitment to Timely Delivery and Outstanding Results<br /> {" "}
                                            
                                            </h4>
                                            <p className="description">As a design agency, we have a proven track record of helping businesses achieve their design goals. Our team of designers, UX experts, and creative professionals have years of experience in their respective fields and bring a wealth of expertise to every project. We pride ourselves on delivering design solutions that are not only visually appealing but also strategically aligned with our clients' business objectives.</p>
                                            <p className="description">Our approach to design is collaborative and iterative, ensuring that our clients are involved throughout the process. We start by conducting in-depth research to understand our clients' target audience, market trends, and competitors. This research informs our design decisions and helps us create designs that resonate with the end-users. We then move on to ideation and prototyping, where we bring our creative concepts to life and refine them based on feedback and testing.</p>
                                            <p className="description">Our team uses industry-leading design tools and techniques to create interactive prototypes and visual designs that are tailored to our clients' specific requirements. We work closely with our clients to gather their feedback and insights, making necessary adjustments to ensure that the final design solution meets their expectations and goals. Our attention to detail and commitment to quality ensure that our design solutions are of the highest standard.</p>
                                            <p className="description">Client-focused approach, transparent communication, timely delivery, exceptional designs, and exceeding expectations are our key strengths. We keep clients involved, provide regular updates, and deliver outstanding results, exceeding their expectations.</p>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 mt_md--30 mt_sm--30">
                                        <h4 className="title"> Our Impact</h4>
                                        <Card className='card bg-transparent' style={{ width: '40rem' }}>
                                            <Card.Img  src="./images/exp/exp-2.jpg" /> <br/>
                                        </Card>
                                            <h5>Design Solutions for Impactful User Experiences</h5>
                                            <p className="description">Impactful design solutions for websites, apps, and branding. Personalized, scalable, and user-centric. Research-based for meaningful results.</p>
                                            <Link className="btn-read-more" to="#"><span>Get The Full Story</span></Link>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                        <Separator/>
                        <div className='container'>
                            <div className='row'>
                                <div className='col-lg-12'>
                                    <div className="text-center">
                                        <SectionTitle
                                            textAlign = "text-center"
                                            radiusRounded = ""
                                            title = "Our Solutions"
                                            description = "Our solutions are tailored, scalable, and personalized for your business needs, including custom website designs,<br/> mobile app interfaces, branding strategies, and interactive prototypes. We prioritize user research, wireframes, and usability <br/>testing to ensure exceptional user experiences and drive business success."
                                        />                                        
                                    </div>
                                    {/* <SlipThree/> */}
                                    <div className="rn-splite-style bg-color-blackest">
                                        <div className="split-wrapper">
                                            <div className="row no-gutters radius-10 align-items-center">
                                                <div className="col-lg-12 col-xl-6 col-12">
                                                    <div className="thumbnail">
                                                        <img src="./images/exp/exp-3.jpg" alt="split Images" />
                                                    </div>
                                                </div>
                                                <div className="col-lg-12 col-xl-6 col-12">
                                                    <div className="split-inner">
                                                        <ScrollAnimation 
                                                        animateIn="fadeInUp"
                                                        animateOut="fadeInOut"
                                                        animateOnce={true}>
                                                            <h4 className="">We Value Your Input and Collaboration</h4>
                                                        </ScrollAnimation>
                                                        <ScrollAnimation 
                                                        animateIn="fadeInUp"
                                                        animateOut="fadeInOut"
                                                        animateOnce={true}>
                                                        <p className="description">We believe in a collaborative approach, where our clients are actively involved in the design process. We value your input and feedback, and we actively seek it at every stage of the project. Your insights and ideas play a critical role in shaping the final design solution. We work closely with our clients to ensure that their vision and requirements are incorporated into the design, resulting in a product that truly reflects their brand identity and resonates with their target audience.</p>
                                                        </ScrollAnimation>
                                                        <Link className="btn-default btn-icon" to="#"> Learn More<i className="icon"><FiArrowRight/></i></Link>
                                                        
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Separator />

                        

                        <Separator/>
                        
                        <div className='container'>
                            <div className='row'>
                                <div className='col-lg-12'>
                                    <div className="text-center">
                                        <SectionTitle
                                            textAlign = "text-center"
                                            radiusRounded = ""
                                            title = "Why Marko"
                                            description = "Choose our company for personalized and scalable design solutions. Our experienced team of designers and UX<br/> experts creates visually appealing, user-friendly, and engaging digital products. With a focus on end-user satisfaction <br/>and engagement, we deliver high-quality design solutions tailored to your unique business requirements."
                                        />
                                        <div className="rn-blog-area rn-section-gap">
                                            <div className="content">
                                                <div className="row mt_dec--30">
                                                    <div className="col-sm rn-card m-4">
                                                        <div className="card bg-transparent border-0" >
                                                            <div className="card-body">
                                                            <ScrollAnimation 
                                                                animateIn="fadeInUp"
                                                                animateOut="fadeInOut"
                                                                animateOnce={true}><br />
                                                                    <h5 className="">Web Design Services</h5>
                                                                </ScrollAnimation>
                                                                <ScrollAnimation 
                                                                animateIn="fadeInUp"
                                                                animateOut="fadeInOut"
                                                                animateOnce={true}>
                                                                <p className="description">Custom web design services for visually stunning, functional websites. Expert designers, latest trends and technologies, responsive design, seamless navigation, exceptional user experience.</p>
                                                                </ScrollAnimation>
                                                                <Link className="btn-read-more" to="#"><span>Learn More</span></Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm rn-card  m-4">
                                                        <div className="card bg-transparent border-0" >
                                                            <div className="card-body">
                                                            <ScrollAnimation 
                                                                animateIn="fadeInUp"
                                                                animateOut="fadeInOut"
                                                                animateOnce={true}><br />
                                                                    <h5 className="">User Experience (UX) Design</h5>
                                                                </ScrollAnimation>
                                                                <ScrollAnimation 
                                                                animateIn="fadeInUp"
                                                                animateOut="fadeInOut"
                                                                animateOnce={true}>
                                                                <p className="description">UX design services for intuitive, enjoyable interactions that delight users. Thorough user research, wireframes, prototypes, and usability testing for higher user satisfaction and engagement in your digital product.</p>
                                                                </ScrollAnimation>
                                                                <Link className="btn-read-more" to="#"><span>Learn More</span></Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className=" col-sm rn-card  m-4">
                                                        <div class="card bg-transparent border-0" >
                                                            <div class="card-body">
                                                            <ScrollAnimation 
                                                                animateIn="fadeInUp"
                                                                animateOut="fadeInOut"
                                                                animateOnce={true}><br />
                                                                    <h5 className="">Mobile App Design</h5>
                                                                </ScrollAnimation>
                                                                <ScrollAnimation 
                                                                animateIn="fadeInUp"
                                                                animateOut="fadeInOut"
                                                                animateOnce={true}>
                                                                <p className="description">Engaging mobile app design optimized for various devices and platforms. Intuitive, visually appealing interfaces that reflect your brand identity for increased user retention.</p>
                                                                </ScrollAnimation>
                                                                <Link className="btn-read-more" to="#"><span>Learn More</span></Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    
                                                </div>
                                            </div>
                                            <div className="content pt-5">
                                                <div className="row mt_dec--30">
                                                    <div className="col-sm rn-card m-4">
                                                        <div className="card bg-transparent border-0" >
                                                            <div className="card-body">
                                                            <ScrollAnimation 
                                                                animateIn="fadeInUp"
                                                                animateOut="fadeInOut"
                                                                animateOnce={true}>
                                                                    <br />
                                                                    <h5 className="">UI/UX Audit and Optimization</h5>
                                                                </ScrollAnimation>
                                                                <ScrollAnimation 
                                                                animateIn="fadeInUp"
                                                                animateOut="fadeInOut"
                                                                animateOnce={true}>
                                                                <p className="description">Improve your digital product with our UI/UX audit and optimization services. Thorough review and actionable recommendations for enhanced usability and functionality.</p>
                                                                </ScrollAnimation>
                                                                <Link className="btn-read-more" to="#"><span>Learn More</span></Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm rn-card  m-4">
                                                        <div className="card bg-transparent border-0" >
                                                            <div className="card-body">
                                                            <ScrollAnimation 
                                                                animateIn="fadeInUp"
                                                                animateOut="fadeInOut"
                                                                animateOnce={true}><br />
                                                                    <h5 className="">Interactive Prototyping</h5>
                                                                </ScrollAnimation>
                                                                <ScrollAnimation 
                                                                animateIn="fadeInUp"
                                                                animateOut="fadeInOut"
                                                                animateOnce={true}>
                                                                <p className="description">Realistic and interactive prototypes for testing and optimizing digital products. Valuable insights for informed decision-making. Enhance functionality and usability for success.</p>
                                                                </ScrollAnimation>
                                                                <Link className="btn-read-more" to="#"><span>View More</span></Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className=" col-sm rn-card  m-4">
                                                        <div class="card bg-transparent border-0" >
                                                            <div class="card-body">
                                                            <ScrollAnimation 
                                                                animateIn="fadeInUp"
                                                                animateOut="fadeInOut"
                                                                animateOnce={true}><br />
                                                                    <h5 className="">Custom Design Solutions</h5>
                                                                </ScrollAnimation>
                                                                <ScrollAnimation 
                                                                animateIn="fadeInUp"
                                                                animateOut="fadeInOut"
                                                                animateOnce={true}>
                                                                <p className="description">Unique design solutions tailored to your business. Custom website design, mobile app interfaces, branding strategy, and experienced designers for personalized and scalable services.</p>
                                                                </ScrollAnimation>
                                                                <Link className="btn-read-more" to="#"><span>View More</span></Link>
                                                            </div>
                                                        </div>
                                                    </div>                                                    
                                                </div>
                                            </div>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Separator />
                        
                </Layout>
        </main>
    </>
  )
}

export default DigitalExperience;