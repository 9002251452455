import React from 'react'
import SEO from '../../../common/SEO';
import { FiArrowRight } from 'react-icons/fi';
import Separator from '../../separator/Separator';
import Layout from '../../../common/Layout';
import SectionTitle from '../../sectionTitle/SectionTitle';
import ScrollAnimation from 'react-animate-on-scroll';
import { Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import VideoOne from '../../video/VideoOne';
const DataAnalytics = () => {
  return (
        <>
            <SEO title={"Data Analytics Solutions"}/>
            <main className="page-wrapper">
            <Layout>
                {/* <HeaderOne btnStyle="btn-small btn-icon round" HeaderSTyle="header-transparent" /> */}
                    <div className="slider-area slider-style-5 bg-overlay-solid height-850 bg_image" data-black-overlay="3" style={{backgroundImage: `url(${process.env.PUBLIC_URL}/images/dA/da-1.jpg)`}}>
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="inner text-start">
                                            <h4 className="subtitle">MARKO</h4>
                                            <h1 className="title theme-gradient">Data analytics <br /> solutions</h1>
                                            <p className="description">Accelerate your digital transformation <br /> journey with advanced analytics and AI.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                    </div>
                {/* End Slider area  */} 
                <Separator />
                        <div className="rn-company-mission-are rn-section-gap">
                            <div className="container">
                                <div className="row row--30">
                                    <div className="col-lg-7">
                                        <div className="mission-title">
                                            <h4 className="title">Data-led innovation<br /> {" "}
                                            
                                            </h4>
                                            <p className="description">Data-led innovation can also optimize operations by analyzing data from various business processes, such as supply chain management, inventory management, and production processes. This can help companies identify areas of inefficiency, streamline operations, and optimize resource allocation, leading to improved productivity, cost savings, and operational excellence.</p>
                                            <p className="description">Moreover, data-led innovation can enable companies to make data-driven strategic decisions. By analyzing market data, competitive intelligence, and industry trends, companies can gain insights that inform strategic planning, business expansion, and investment decisions, leading to more informed and strategic business decisions.</p>
                                            <p className="description">In conclusion, data-led innovation can bring significant benefits to companies by unlocking new opportunities, optimizing operations, enhancing customer engagement, and enabling data-driven decision-making. It involves analyzing data to uncover insights that inform innovation across various areas of the business, leading to improved business performance and competitive advantage.</p>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 mt_md--30 mt_sm--30">
                                        <h4 className="title"> Our Impact</h4>
                                        <Card className='card bg-transparent' style={{ width: '40rem' }}>
                                            <Card.Img  src="./images/dA/da-3.jpg" /> <br/>
                                        </Card>
                                            <h5>Advance data analytics service </h5>
                                            <p className="description">Data-led innovation through advanced analytics services for companies: predictive analytics, prescriptive analytics, exploratory data analysis, and big data technologies for insights, optimization, and competitive advantage.</p>
                                            <Link className="btn-read-more" to="#"><span>View More</span></Link>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                        <div className="rwt-video-area rn-section-gapBottom">
                    <div className="container">
                        <div className="row">
                            <VideoOne />
                        </div>
                    </div>
                </div>
                <Separator/>
                        <div className='container'>
                            <div className='row'>
                                <div className='col-lg-12'>
                                    <div className="text-center">
                                        <SectionTitle
                                            textAlign = "text-center"
                                            radiusRounded = ""
                                            title = "Our Solutions"
                                            description = "Across industries and functions, our analytics offerings turn data into actionable insights, empowering teams to make <br/> decisions at  speed – the type of decisions that drive long-term growth and deliver competitive advantages for our clients."
                                        />                                        
                                    </div>
                                    <div className="rn-splite-style bg-color-blackest">
                                        <div className="split-wrapper">
                                            <div className="row no-gutters radius-10 align-items-center">
                                                <div className="col-lg-12 col-xl-6 col-12">
                                                    <div className="thumbnail">
                                                        <img src="./images/dA/da-2.jpg" alt="split Images" />
                                                    </div>
                                                </div>
                                                <div className="col-lg-12 col-xl-6 col-12">
                                                    <div className="split-inner">
                                                        <ScrollAnimation 
                                                        animateIn="fadeInUp"
                                                        animateOut="fadeInOut"
                                                        animateOnce={true}>
                                                            <h4 className="">Power Me</h4>
                                                        </ScrollAnimation>
                                                        <ScrollAnimation 
                                                        animateIn="fadeInUp"
                                                        animateOut="fadeInOut"
                                                        animateOnce={true}>
                                                        <p className="description">An active metadata platform with intelligent data catalog, automated lineage and connected data quality capabilities to maximize your enterprise's data intelligence.</p>
                                                        </ScrollAnimation>
                                                        <Link className="btn-default btn-icon" to="#"> Learn More<i className="icon"><FiArrowRight/></i></Link>
                                                        
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                <Separator />
                <div className='container'>
                            <div className='row'>
                                <div className='col-lg-12'>
                                    <div className="text-center">
                                        <div className="rn-blog-area rn-section-gap">
                                            <div className="content">
                                                <div className="row mt_dec--30">
                                                    <div className="col-sm rn-card m-4">
                                                        <div className="card bg-transparent border-0" >
                                                            <div className="card-body">
                                                            <ScrollAnimation 
                                                                animateIn="fadeInUp"
                                                                animateOut="fadeInOut"
                                                                animateOnce={true}><br />
                                                                    <h5 className="">Cora data foundation</h5>
                                                                </ScrollAnimation>
                                                                <ScrollAnimation 
                                                                animateIn="fadeInUp"
                                                                animateOut="fadeInOut"
                                                                animateOnce={true}>
                                                                <p className="description">The robust and scalable data infrastructure that powers data management and analytics within the Cora ecosystem, enabling efficient data integration, data governance, and data analytics for data-driven decision-making.</p>
                                                                </ScrollAnimation>
                                                                <Link className="btn-read-more" to="#"><span>Learn More</span></Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm rn-card  m-4">
                                                        <div className="card bg-transparent border-0" >
                                                            <div className="card-body">
                                                            <ScrollAnimation 
                                                                animateIn="fadeInUp"
                                                                animateOut="fadeInOut"
                                                                animateOnce={true}><br />
                                                                    <h5 className="">Cora Finance Analytics</h5>
                                                                </ScrollAnimation>
                                                                <ScrollAnimation 
                                                                animateIn="fadeInUp"
                                                                animateOut="fadeInOut"
                                                                animateOnce={true}>
                                                                <p className="description">Cora Finance Analytics: Data-driven financial insights for better decision-making, leveraging advanced analytics for financial performance analysis, planning, and optimization.</p>
                                                                </ScrollAnimation>
                                                                <Link className="btn-read-more" to="#"><span>Learn More</span></Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className=" col-sm rn-card  m-4">
                                                        <div class="card bg-transparent border-0" >
                                                            <div class="card-body">
                                                            <ScrollAnimation 
                                                                animateIn="fadeInUp"
                                                                animateOut="fadeInOut"
                                                                animateOnce={true}><br />
                                                                    <h5 className="">Supply chain analytics</h5>
                                                                </ScrollAnimation>
                                                                <ScrollAnimation 
                                                                animateIn="fadeInUp"
                                                                animateOut="fadeInOut"
                                                                animateOnce={true}>
                                                                <p className="description">An analytics suite of solutions to transform supply chain operations with inventory optimization, demand predictions, performance metrics, and the financial implications of supply chain performance.</p>
                                                                </ScrollAnimation>
                                                                <Link className="btn-read-more" to="#"><span>Learn More</span></Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    
                                                </div>
                                            </div>
                                            <div className="content pt-5">
                                                <div className="row mt_dec--30">
                                                    <div className="col-sm rn-card m-4">
                                                        <div className="card bg-transparent border-0" >
                                                            <div className="card-body">
                                                            <ScrollAnimation 
                                                                animateIn="fadeInUp"
                                                                animateOut="fadeInOut"
                                                                animateOnce={true}>
                                                                    <br />
                                                                    <h5 className="">Machine learning operations</h5>
                                                                </ScrollAnimation>
                                                                <ScrollAnimation 
                                                                animateIn="fadeInUp"
                                                                animateOut="fadeInOut"
                                                                animateOnce={true}>
                                                                <p className="description">A new approach to implementing AI and machine learning technologies, ensuring consistency, and mitigating the potential risks associated with not having a framework for scalable innovation.</p>
                                                                </ScrollAnimation>
                                                                <Link className="btn-read-more" to="#"><span>Learn More</span></Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm rn-card  m-4">
                                                        <div className="card bg-transparent border-0" >
                                                            <div className="card-body">
                                                            <ScrollAnimation 
                                                                animateIn="fadeInUp"
                                                                animateOut="fadeInOut"
                                                                animateOnce={true}><br />
                                                                    <h5 className="">Analytics Maturity Meter (AMM)</h5>
                                                                </ScrollAnimation>
                                                                <ScrollAnimation 
                                                                animateIn="fadeInUp"
                                                                animateOut="fadeInOut"
                                                                animateOnce={true}>
                                                                <p className="description">We offer a multidimensional framework to measure your organization's data and analytics maturity, finding opportunities to improve your analytics capabilities.</p>
                                                                </ScrollAnimation>
                                                                <Link className="btn-read-more" to="#"><span>View More</span></Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className=" col-sm rn-card  m-4">
                                                        <div class="card bg-transparent border-0" >
                                                            <div class="card-body">
                                                            <ScrollAnimation 
                                                                animateIn="fadeInUp"
                                                                animateOut="fadeInOut"
                                                                animateOnce={true}><br />
                                                                    <h5 className="">Commercial analytics</h5>
                                                                </ScrollAnimation>
                                                                <ScrollAnimation 
                                                                animateIn="fadeInUp"
                                                                animateOut="fadeInOut"
                                                                animateOnce={true}>
                                                                <p className="description">Using predictive models and data visualization, we help pharmaceutical companies optimize their commercial spend across marketing, sales, and payer rebates.</p>
                                                                </ScrollAnimation>
                                                                <Link className="btn-read-more" to="#"><span>View More</span></Link>
                                                            </div>
                                                        </div>
                                                    </div>                                                                                                     
                                                </div>
                                            </div>
                                            <div className="content pt-5">
                                                <div className="row mt_dec--30">
                                                    <div className="col-sm rn-card m-4">
                                                        <div className="card bg-transparent border-0" >
                                                            <div className="card-body">
                                                            <ScrollAnimation 
                                                                animateIn="fadeInUp"
                                                                animateOut="fadeInOut"
                                                                animateOnce={true}>
                                                                    <br />
                                                                    <h5 className="">Consumer analytics</h5>
                                                                </ScrollAnimation>
                                                                <ScrollAnimation 
                                                                animateIn="fadeInUp"
                                                                animateOut="fadeInOut"
                                                                animateOnce={true}>
                                                                <p className="description">We help enterprises attract new customers, improve marketing ROI, and enhance sales effectiveness. Our analytics-driven solutions reveal and measure the entire customer journey from awareness to advocacy.</p>
                                                                </ScrollAnimation>
                                                                <Link className="btn-read-more" to="#"><span>Learn More</span></Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    
                                                    <div className=" col-sm rn-card  m-4">
                                                        <div class="card bg-transparent border-0" >
                                                            <div class="card-body">
                                                            <ScrollAnimation 
                                                                animateIn="fadeInUp"
                                                                animateOut="fadeInOut"
                                                                animateOnce={true}><br />
                                                                    <h5 className="">Financial crime risk management</h5>
                                                                </ScrollAnimation>
                                                                <ScrollAnimation 
                                                                animateIn="fadeInUp"
                                                                animateOut="fadeInOut"
                                                                animateOnce={true}>
                                                                <p className="description">We guide companies through financial crime compliance, corporate governance, and risk analytics across customer screening, fraud protection, anti-money laundering, and trade surveillance.</p>
                                                                </ScrollAnimation>
                                                                <Link className="btn-read-more" to="#"><span>View More</span></Link>
                                                            </div>
                                                        </div>
                                                    </div>                                                                                                     
                                                </div>
                                            </div>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Separator />
                        {/* <div className='container'>
                            <div className='row'>
                                <div className='col-lg-12'>
                                    <div className="text-center">
                                        <SectionTitle
                                            textAlign = "text-center"
                                            radiusRounded = ""
                                            title = "Why Marko"
                                            description = "We create strong data foundations, develop prescriptive engines, and democratize access to insights <br />across the organization.  Our process and digital expertise help our clients gain value from data, <br />resulting in higher employee satisfaction, productivity, customer loyalty, and business growth."
                                        />
                                        <SlipFour />
                                    </div>
                                </div>
                            </div>
                        </div> */}
                        <Separator />
                </Layout>
            </main>
        </>
  );
}

export default DataAnalytics