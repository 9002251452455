import React from 'react'
import { Accordion, Card, Button } from 'react-bootstrap';

const AccordionOne = ({customStyle}) => {
    return (
        <Accordion className={`rn-accordion-style ${customStyle}`} defaultActiveKey="0">
            <Card>
                <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey="0">
                What kind of software solutions do you offer?
                </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="0">
                    <Card.Body>We offer a wide range of software solutions, including web and mobile app development, custom software development, cloud computing, artificial intelligence and machine learning, and more!. </Card.Body>
                </Accordion.Collapse>
            </Card>

            <Card>
                <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey="1">
                What industries have you worked with in the past?
                </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="1">
                    <Card.Body>We have worked with a variety of industries in the past, including healthcare, finance, education, e-commerce, and more. Our team has experience working with companies of all sizes, from startups to large corporations, and we have a deep understanding of the unique needs and challenges facing each industry.</Card.Body>
                </Accordion.Collapse>
            </Card>

            <Card>
                <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey="2">
                How do you ensure the quality of your software products?
                </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="2">
                    <Card.Body>We take quality assurance very seriously, and we use a variety of techniques and tools to ensure that our software products are of the highest quality. This includes thorough testing, code reviews, and continuous integration and delivery practices. We also have a dedicated quality assurance team that works to ensure that our software meets the highest standards of reliability, efficiency, and user-friendliness.</Card.Body>
                </Accordion.Collapse>
            </Card>

            <Card>
                <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey="3">
                Do you provide support and maintenance for your software products?
                </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="3">
                    <Card.Body>Yes, we provide ongoing support and maintenance for all of our software products. We offer a range of support and maintenance packages that are tailored to the specific needs of our clients, and we are committed to providing timely and effective support to ensure that our clients' software solutions continue to meet their needs and requirements.</Card.Body>
                </Accordion.Collapse>
            </Card>

            <Card>
                <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey="3">
                How do you ensure the security and privacy of sensitive data?
                </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="3">
                    <Card.Body>We take the security and privacy of our clients' data very seriously, and we use a variety of techniques and tools to ensure that sensitive data is protected at all times. This includes using secure coding practices, implementing encryption and authentication protocols, and following industry-standard security and privacy frameworks such as GDPR and HIPAA.</Card.Body>
                </Accordion.Collapse>
            </Card>
        </Accordion>
    )
}
export default AccordionOne

