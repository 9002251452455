import React from 'react';
import { FiFacebook, FiTwitter, FiInstagram, FiLinkedin } from "react-icons/fi";

import {Link} from "react-router-dom";

const CopyrightTwo = () => {
    return (
        <div className="copyright-area copyright-style-one variation-two">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-4 col-md-7 col-sm-12 col-12">
                        <div className="copyright-left">
                            <ul className="ft-menu link-hover">
                                <li><Link to="/privacy-policy">Privacy Policy</Link></li>
                                <li><Link to="/terms-and-conditions">Terms And Condition</Link></li>
                                <li><Link to="/contact-us">Contact Us</Link></li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-5 mt_sm--20">
                        <div className="copyright-center text-center">
                            <ul className="social-icon social-default with-gradient justify-content-center ">
                                <li><a href="https://www.facebook.com/marko.software" target="_blank" rel="noopener noreferrer"><FiFacebook /></a></li>
                                <li><a href="https://www.twitter.com" target="_blank" rel="noopener noreferrer"><FiTwitter /></a></li>
                                <li><a href="https://www.instagram.com/itmarko.in" target="_blank" rel="noopener noreferrer"><FiInstagram /></a></li>
                                <li><a href="https://www.linkedin.com/company/marko-in" target="_blank" rel="noopener noreferrer"><FiLinkedin /></a></li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-12 col-sm-12 col-12 mt_md--20 mt_sm--20">
                        <div className="copyright-right text-center text-lg-right">
                            <p className="copyright-text">Copyright © {new Date().getFullYear()} Marko. All Rights Reserved.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default CopyrightTwo;