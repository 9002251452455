import React from 'react'

import SEO from '../../../common/SEO';
import HeaderOne from '../../../common/header/HeaderOne';
import Layout from '../../../common/Layout';
import { Link } from 'react-router-dom';
import Separator from '../../separator/Separator';
import ScrollAnimation from 'react-animate-on-scroll';
import { Card } from 'react-bootstrap';
import SlipThree from '../../split/SlipThree';
import SectionTitle from '../../sectionTitle/SectionTitle';
import BlogList from '../../../components/blog/itemProp/BlogList';
import BlogClassicData from '../../../data/blog/BlogList.json';
var BlogListData = BlogClassicData.slice(0, 3);
const  Artificial=({ data, StyleVar })=> {
  return (
    <>
        <SEO title={"Artificial Intelligence Solutions"}/>
        <main className="page-wrapper">
            <HeaderOne btnStyle="btn-small btn-icon round" HeaderSTyle="header-transparent"/>
                <Layout>
                        <div className="slider-area slider-style-6 shape-right bg-transparent height-650">
                            <div className="container">
                                <div className="row row--30 align-items-center">
                                    <div className="order-2 order-lg-1 col-lg-6 mt_md--50 mt_sm--50 mt_lg--30">
                                        <div className="inner text-start">
                                            <h1 className="title theme-gradient">Artificial intelligence solutions</h1>
                                            <p className="description">We specialize in artificial intelligence (AI), machine learning (ML), and insights solutions to help you transform your business at scale.</p>
                                            {/* <div className="button-group mt--30 mt_sm--20">
                                                
                                            </div> */}
                                        </div>
                                    </div>

                                    <div className="col-lg-6 order-1 order-lg-2">
                                        <div className="thumbnail">
                                            <img src="./images/ai/ai-1.jpg" alt="Banner Images" />
                                        </div>
                                    </div>
                                    <div className="shape-image">
                                        <img src="./images/banner/white-shape.svg" alt="Banner Images" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* End Slider Area  */}
                        <Separator />
                        <div className="rn-company-mission-are rn-section-gap">
                            <div className="container">
                                <div className="row row--30">
                                    <div className="col-lg-7">
                                        <div className="mission-title">
                                            <h4 className="title">AI for business: Redefining how we live and work <br /> {" "}
                                            
                                            </h4>
                                            <p className="description">Across industries, enterprise leaders are under pressure to reinvent business processes with AI-fueled innovation. But they often struggle to identify high-ROI use cases and make informed decisions to drive adoption at scale. Our AI/ML and insights solutions are technology agnostic and designed keeping your business goals in mind. What's more, we offer governance models to ensure your data is trustworthy and free from biases.</p>
                                            <p className="description">From advisory and strategy consulting services to developing complex AI solutions, we enable full-stack AI/ML and machine learning operations (MLOps) implementation at scale. Our responsible AI framework integrates controls and governance metrics to future-proof your AI investments. And through our robust partner ecosystem, we tackle every business challenge from beginning to end, enabling the journey of data to insights to action.</p>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 mt_md--30 mt_sm--30">
                                        <h4 className="title"> Our Impact</h4>
                                        <Card className='card bg-transparent' style={{ width: '40rem' }}>
                                            <Card.Img  src="./images/ai/ai-2.jpg" /> <br/>
                                        </Card>
                                            <h5>Become a data-driven enterprise </h5>
                                            <p className="description">Learn how to become a data-driven enterprise and maximize the value of artificial intelligence and machine lear </p>
                                            <Link className="btn-read-more" to="#"><span>View More</span></Link>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                        <Separator/>
                        <div className='container'>
                            <div className='row'>
                                <div className='col-lg-12'>
                                    <div className="text-center">
                                        <SectionTitle
                                            textAlign = "text-center"
                                            radiusRounded = ""
                                            title = "Our Solutions"
                                            description = "AI has the power to transform business outcomes and enhance customer experiences. Our technology capabilities <br />and expertise go beyond specific functions or industries, putting people at the heart of everything we do."
                                        />                                        
                                    </div>
                                    <SlipThree/>
                                </div>
                            </div>
                        </div>
                        <Separator />

                        

                        <Separator/>
                        
                        <div className='container'>
                            <div className='row'>
                                <div className='col-lg-12'>
                                    <div className="text-center">
                                        <SectionTitle
                                            textAlign = "text-center"
                                            radiusRounded = ""
                                            title = "Why Marko"
                                            description = "We redefine how organizations operate in the digital age. From transforming business models using automation to building<br /> new  solutions with artificial intelligence and machine learning, we use technology to make the world work better for people."
                                        />
                                        <div className="rn-blog-area rn-section-gap">
                                            <div className="content">
                                                <div className="row mt_dec--30">
                                                    <div className="col-sm rn-card m-4">
                                                        <div className="card bg-transparent border-0" >
                                                            <div className="card-body">
                                                            <ScrollAnimation 
                                                                animateIn="fadeInUp"
                                                                animateOut="fadeInOut"
                                                                animateOnce={true}><br />
                                                                    <h5 className="">Full-stack AI/ML implementation</h5>
                                                                </ScrollAnimation>
                                                                <ScrollAnimation 
                                                                animateIn="fadeInUp"
                                                                animateOut="fadeInOut"
                                                                animateOnce={true}>
                                                                <p className="description">Develop production-ready solutions with our specialized skills in structured machine/deep learning, computer vision, natural language processing (NLP)/text mining, forecasting/optimization/simulation, and conversational AI.</p>
                                                                </ScrollAnimation>
                                                                <Link className="btn-read-more" to="#"><span>Learn More</span></Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm rn-card  m-4">
                                                        <div className="card bg-transparent border-0" >
                                                            <div className="card-body">
                                                            <ScrollAnimation 
                                                                animateIn="fadeInUp"
                                                                animateOut="fadeInOut"
                                                                animateOnce={true}><br />
                                                                    <h5 className="">MLOps advisory and implementation</h5>
                                                                </ScrollAnimation>
                                                                <ScrollAnimation 
                                                                animateIn="fadeInUp"
                                                                animateOut="fadeInOut"
                                                                animateOnce={true}>
                                                                <p className="description">Evaluate your readiness to implement MLOps. Transform MLOps implementations from minimally viable products (MVPs) to full-scale with proven assessment frameworks and a right-fit tech stack.</p>
                                                                </ScrollAnimation>
                                                                <Link className="btn-read-more" to="#"><span>Learn More</span></Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className=" col-sm rn-card  m-4">
                                                        <div class="card bg-transparent border-0" >
                                                            <div class="card-body">
                                                            <ScrollAnimation 
                                                                animateIn="fadeInUp"
                                                                animateOut="fadeInOut"
                                                                animateOnce={true}><br />
                                                                    <h5 className="">Responsible AI</h5>
                                                                </ScrollAnimation>
                                                                <ScrollAnimation 
                                                                animateIn="fadeInUp"
                                                                animateOut="fadeInOut"
                                                                animateOnce={true}>
                                                                <p className="description">Use our responsible AI framework to create and implement fair and ethical AI and ML solutions. Plus, our AI center of excellence (CoE) connects diverse groups of custodians who can oversee the development of artificial intelligence from start to finish.</p>
                                                                </ScrollAnimation>
                                                                <Link className="btn-read-more" to="#"><span>Learn More</span></Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    
                                                </div>
                                            </div>
                                            <div className="content pt-5">
                                                <div className="row mt_dec--30">
                                                    <div className="col-sm rn-card m-4">
                                                        <div className="card bg-transparent border-0" >
                                                            <div className="card-body">
                                                            <ScrollAnimation 
                                                                animateIn="fadeInUp"
                                                                animateOut="fadeInOut"
                                                                animateOnce={true}>
                                                                    <br />
                                                                    <h5 className="">MLOps readiness assessment and roadmap</h5>
                                                                </ScrollAnimation>
                                                                <ScrollAnimation 
                                                                animateIn="fadeInUp"
                                                                animateOut="fadeInOut"
                                                                animateOnce={true}>
                                                                <p className="description">Find out if your business is ready to implement artificial intelligence across your enterprise by evaluating factors like technology, talent, process, data, and leadership buy-in.</p>
                                                                </ScrollAnimation>
                                                                <Link className="btn-read-more" to="#"><span>Learn More</span></Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm rn-card  m-4">
                                                        <div className="card bg-transparent border-0" >
                                                            <div className="card-body">
                                                            <ScrollAnimation 
                                                                animateIn="fadeInUp"
                                                                animateOut="fadeInOut"
                                                                animateOnce={true}><br />
                                                                    <h5 className="">Analytics Maturity Meter (AMM)</h5>
                                                                </ScrollAnimation>
                                                                <ScrollAnimation 
                                                                animateIn="fadeInUp"
                                                                animateOut="fadeInOut"
                                                                animateOnce={true}>
                                                                <p className="description">We offer a multidimensional framework to measure your organization's data and analytics maturity, finding opportunities to improve your analytics capabilities.</p>
                                                                </ScrollAnimation>
                                                                <Link className="btn-read-more" to="#"><span>View More</span></Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className=" col-sm rn-card  m-4">
                                                        <div class="card bg-transparent border-0" >
                                                            <div class="card-body">
                                                            <ScrollAnimation 
                                                                animateIn="fadeInUp"
                                                                animateOut="fadeInOut"
                                                                animateOnce={true}><br />
                                                                    <h5 className="">Advanced analytics CoE managed services</h5>
                                                                </ScrollAnimation>
                                                                <ScrollAnimation 
                                                                animateIn="fadeInUp"
                                                                animateOut="fadeInOut"
                                                                animateOnce={true}>
                                                                <p className="description">Optimize business performance and commercialize data opportunities – build and deploy analytics models, manage analytics infrastructure, generate business insights, and create a governance structure through our analytics centers of excellence.</p>
                                                                </ScrollAnimation>
                                                                <Link className="btn-read-more" to="#"><span>View More</span></Link>
                                                            </div>
                                                        </div>
                                                    </div>                                                    
                                                </div>
                                            </div>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Separator />
                        <div className="blog-area rn-section-gap">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <SectionTitle
                                    textAlign = "text-center"
                                    radiusRounded = ""
                                    subtitle = "Latests News"
                                    title = "Our Latest News."
                                    description = "We provide company and finance service for <br /> startups and company business."
                                />
                            </div>
                        </div>
                        <div className="row row--15">
                            {BlogListData.map((item) => (
                                <div key={item.id} className="col-lg-4 col-md-6 col-12 mt--30">
                                    <BlogList StyleVar="box-card-style-default" data={item} />
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                </Layout>
        </main>
    </>
  )
}

export default Artificial;