import React from 'react'
import SEO from '../../../common/SEO';
import { Card } from 'react-bootstrap';
import { FiArrowRight } from 'react-icons/fi';
import HeaderOne from '../../../common/header/HeaderOne';
import Layout from '../../../common/Layout';
import { Link } from 'react-router-dom';
import Separator from '../../separator/Separator';
import ScrollAnimation from 'react-animate-on-scroll';
import SectionTitle from '../../sectionTitle/SectionTitle';
import Typed from 'react-typed';
const EnterpriseSolutions =()=> {
  return (
    <>
        <SEO title={"Enterprise Solutions Services"}/>
        <main className="page-wrapper">
            <HeaderOne btnStyle="btn-small btn-icon round" HeaderSTyle="header-transparent"/>
                <Layout>
                <div className="slider-area slider-style-5 bg-overlay-solid height-850 bg_image" data-black-overlay="3" style={{backgroundImage: `url(${process.env.PUBLIC_URL}/images/enterpricesolution/es-1.jpg)`}}>
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="inner text-start">
                                            <h4 className="subtitle">MARKO</h4>
                                            <h1 className="title theme-gradient display-two">
                                            <Typed
                                              strings={[
                                                "Enterprise <br> Solutions",
                                            ]}
                                            typeSpeed={80}
                                            backSpeed={5}
                                            backDelay={1000}
                                            loop
                                            />
                                            </h1>
                                            <p className="description">Boost your business with our tailored enterprise solutions.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                    </div>
                {/* End Slider area  */} 
                <Separator />
                <div className="rn-company-mission-are rn-section-gap">
                            <div className="container">
                                <div className="row row--30">
                                    <div className="col-lg-7">
                                        <div className="mission-title">
                                            <h4 className="title">Enterprise Solutions {" "}
                                            </h4>
                                            <p className="description">Our enterprise solutions are designed to streamline and optimize your business operations. With automation, process optimization, and seamless integration, we help you eliminate inefficiencies, reduce manual work, and enhance productivity across your organization.</p>
                                            <p className="description">Our enterprise solutions empower you to drive growth and stay ahead of the competition. From scalable infrastructure to advanced analytics, we provide the tools and insights you need to make informed decisions, identify new opportunities, and innovate in the rapidly evolving business landscape.</p>
                                            <p className="description">Security is paramount in the enterprise environment. Our solutions prioritize data security, access controls, and compliance with industry regulations. Rest assured that your sensitive information is protected, giving you peace of mind to focus on your core business objectives.</p>
                                            <p className="description">Tailored enterprise solutions for your unique needs, driving growth, streamlining operations, ensuring security, and delivering innovation. Experience the power of our comprehensive suite of solutions.</p>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 mt_md--30 mt_sm--30">
                                        <h4 className="title"> Our Impact</h4>
                                        <Card className='card bg-transparent' style={{ width: '40rem' }}>
                                            <Card.Img  src="./images/enterpricesolution/es-2.jpg" /> <br/>
                                        </Card>
                                            <h5>Transformative Enterprise Solutions for Your Business Success</h5>
                                            <p className="description">Our enterprise solution delivers impactful results, transforming operations and driving growth for your business.</p>
                                            <Link className="btn-read-more" to="#"><span>View More</span></Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Separator/>
                        <div className='container'>
                            <div className='row'>
                                <div className='col-lg-12'>
                                    <div className="text-center">
                                        <SectionTitle
                                            textAlign = "text-center"
                                            radiusRounded = ""
                                            title = "Our Solutions"
                                            description = "Combines advanced technology, streamlined workflows, and actionable insights to optimize your business operations.<br> Experience scalability, security, and customization with our comprehensive suite of solutions tailored to your specific needs.<br> Trust us to drive efficiency, productivity, and growth for your organization."
                                        />                                        
                                    </div>
                                    {/* <SlipThree/> */}
                                    <div className="rn-splite-style bg-color-blackest">
                                        <div className="split-wrapper">
                                            <div className="row no-gutters radius-10 align-items-center">
                                                <div className="col-lg-12 col-xl-6 col-12">
                                                    <div className="thumbnail">
                                                        <img src="/images/enterpricesolution/es-3.jpg" alt="split Images" />
                                                    </div>
                                                </div>
                                                <div className="col-lg-12 col-xl-6 col-12">
                                                    <div className="split-inner">
                                                        <ScrollAnimation 
                                                        animateIn="fadeInUp"
                                                        animateOut="fadeInOut"
                                                        animateOnce={true}>
                                                        <h4 className="">Powerful Enterprise Solutions for Business Success</h4>
                                                        </ScrollAnimation>
                                                        <ScrollAnimation 
                                                        animateIn="fadeInUp"
                                                        animateOut="fadeInOut"
                                                        animateOnce={true}>
                                                        <p className="description">Empower businesses with scalable infrastructure, workflow automation, advanced analytics, and enhanced security. Drive growth, efficiency, and success with our comprehensive suite of tailored solutions.</p>
                                                        </ScrollAnimation>
                                                        <Link className="btn-default btn-icon" to="#"> Learn More<i className="icon"><FiArrowRight/></i></Link>                                                        
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Separator />                        
                        <div className='container'>
                            <div className='row'>
                                <div className='col-lg-12'>
                                    <div className="text-center">
                                        <SectionTitle
                                            textAlign = "text-center"
                                            radiusRounded = ""
                                            title = "Why Marko"
                                            description = "Choose us for enterprise solutions: expertise, customization, security, support, client satisfaction.<br> We deliver cost-effective, high-quality solutions that drive success for your business."
                                        />
                                        <div className="rn-blog-area rn-section-gap">
                                            <div className="content">
                                                <div className="row mt_dec--30">
                                                    <div className="col-sm rn-card m-4">
                                                        <div className="card bg-transparent border-0" >
                                                            <div className="card-body">
                                                                <ScrollAnimation 
                                                                    animateIn="fadeInUp"
                                                                    animateOut="fadeInOut"
                                                                    animateOnce={true}><br />
                                                                    <h5 className="">Scalable and Secure Infrastructure</h5>
                                                                    </ScrollAnimation>
                                                                    <ScrollAnimation 
                                                                    animateIn="fadeInUp"
                                                                    animateOut="fadeInOut"
                                                                    animateOnce={true}>
                                                                    <p className="description">Our enterprise solutions provide a scalable and secure infrastructure that can handle the needs of your growing business. With robust cloud-based architecture, we ensure high availability and data protection.</p>
                                                                </ScrollAnimation>
                                                                <Link className="btn-read-more" to="#"><span>Learn More</span></Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm rn-card  m-4">
                                                        <div className="card bg-transparent border-0" >
                                                            <div className="card-body">
                                                            <ScrollAnimation 
                                                                animateIn="fadeInUp"
                                                                animateOut="fadeInOut"
                                                                animateOnce={true}><br />
                                                                <h5 className="">Customized Workflow Automation</h5>
                                                                </ScrollAnimation>
                                                                <ScrollAnimation 
                                                                animateIn="fadeInUp"
                                                                animateOut="fadeInOut"
                                                                animateOnce={true}>
                                                                <p className="description">We offer tailored workflow automation solutions that streamline your business processes, saving time and reducing human errors. From document management to task automation, our solutions optimize efficiency.</p>
                                                                </ScrollAnimation>
                                                                <Link className="btn-read-more" to="#"><span>Learn More</span></Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className=" col-sm rn-card  m-4">
                                                        <div class="card bg-transparent border-0" >
                                                            <div class="card-body">
                                                            <ScrollAnimation 
                                                                animateIn="fadeInUp"
                                                                animateOut="fadeInOut"
                                                                animateOnce={true}><br />
                                                                <h5 className="">Data Analytics and Business Intelligence</h5>
                                                                </ScrollAnimation>
                                                                <ScrollAnimation 
                                                                animateIn="fadeInUp"
                                                                animateOut="fadeInOut"
                                                                animateOnce={true}>
                                                                <p className="description">Unlock valuable insights from your data with our advanced analytics and business intelligence tools. Make data-driven decisions and gain a competitive edge in the market with our comprehensive reporting and visualization capabilities.</p>
                                                                </ScrollAnimation>
                                                                <Link className="btn-read-more" to="#"><span>Learn More</span></Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>                     
                </Layout>
        </main>
    </>
  )
}

export default EnterpriseSolutions;