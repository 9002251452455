import React from 'react';
import { Link } from 'react-router-dom';
import Typed from 'react-typed';

const AboutOne = () => {
    return (
        <div className="rwt-about-area rn-section-gap">
            <div className="container">
                <div className="row row--30 align-items-center">
                    <div className="col-lg-5">
                        <div className="thumbnail">
                            <img className="w-100" src="./images/about/about-1.png" alt="About Images" />
                        </div>
                    </div>

                    <div className="col-lg-7 mt_md--40 mt_sm--40">
                        <div className="content">
                            <div className="section-title">
                                <h2 className="title">Unique Business <br /> {" "}
                                    <Typed className="theme-gradient"
                                        strings={[
                                            "Consulting.",
                                            "Soft solutions.",
                                            "Digital solutions.",
                                        ]}
                                        typeSpeed={80}
                                        backSpeed={5}
                                        backDelay={1000}
                                        loop
                                    />
                                </h2>
                                <p>At MARKO, we understand the importance of having the right technology solutions for your business. With our years of experience in the software industry, we provide expert software consultancy services to help businesses achieve their goals and drive growth.</p>
                                <p>Our team of consultants is highly skilled in a variety of software technologies, including web and mobile app development, cloud computing, machine learning, and more. We work closely with our clients to understand their unique needs and challenges, and we provide tailored solutions to meet those needs.</p>
                                <div className="read-more-btn mt--40">
                                    <Link className="btn-default"to="/about-us"><span>More About Us</span></Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>   
        </div>
    )
}

export default AboutOne
