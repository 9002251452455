import React from 'react';
import Slider from "react-slick";
import { slickDot } from "../../utils/script";

const TestimonialData = [
    {
        form: "Raipur",
        description: "Professional, communicative, and delivered exactly what I was looking for. Highly recommend for top-notch app development services.",
        name: "Abhishek ",
        subtitle: "App Development",
        image: "testimonial-dark-01"
    },
    {
        form: "Hyderabad",
        description: "I highly recommend itmarko for their exceptional web development services. Their work significantly improved my online presence.",
        name: "YB Reddy",
        subtitle: "E-commerce",
        image: "testimonial-dark-02"
    },
    {
        form: "Mumbai",
        description: "Itmarko's web development services exceeded expectations, delivering a visually stunning and user-friendly site that greatly improved our online presence.",
        name: "Mukesh Sharma",
        subtitle: "Website development",
        image: "testimonial-dark-03"
    },
    {
        form: "Kolkata",
        description: "Working with itmarko was great. Their team was knowledgeable and delivered a high-quality web app that met my business needs. I highly recommend them.",
        name: "Kunal",
        subtitle: "Web App development",
        image: "testimonial-dark-04"
    },


    
]
const TestimonialThree = ({teamStyle}) => {
    return (
        <div className="row">
            <div className="col-lg-12">
                <Slider className="slick-space-gutter--15 rn-slick-dot rn-slick-arrow mb--60" {...slickDot}>
                    {TestimonialData.map((data, index) => (
                        <div key={index} className={`testimonial-style-two ${teamStyle}`}>
                            <div className="row align-items-center row--20">
                                <div className="order-2 order-md-1 col-lg-6 col-md-8 offset-lg-1">
                                    <div className="content mt_sm--40">
                                        <span className="form">{data.form}</span>
                                        <p className="description">{data.description}</p>
                                        <div className="client-info">
                                            <h4 className="title">{data.name}</h4>
                                            <h6 className="subtitle">{data.subtitle}</h6>
                                        </div>
                                    </div>
                                </div>
                                <div className="order-1 order-md-2 col-lg-4 col-md-4">
                                    <div className="thumbnail">
                                        <img className="w-100" src={`./images/testimonial/${data.image}.jpg`} alt="image" />
                                    </div>
                                </div>
                            </div> 
                        </div>
                    ))}
                </Slider>
            </div>
        </div>
    )
}
export default TestimonialThree;




















