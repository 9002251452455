import React from 'react';
import Typed from 'react-typed';
import {Link} from "react-router-dom";
import { FiArrowRight } from "react-icons/fi";
import AccordionOne from '../accordion/AccordionOne';

const mission = () => {
    return (
        <div className="rn-company-mission-are rn-section-gap">
            <div className="container">
                <div className="row row--30">
                    <div className="col-lg-6">
                        <div className="mission-title">
                            <h2 className="title">Unique Vission <br /> {" "}
                                <Typed className="theme-gradient"
                                    strings={[
                                        "Innovate.",
                                        "Automate.",
                                        "Transform.",
                                        "Enhance.",
                                        "Optimize.",
                                    ]}
                                    typeSpeed={80}
                                    backSpeed={5}
                                    backDelay={1000}
                                    loop
                                />
                            </h2>
                            <p>Our goal is to help our clients achieve maximum return on investment by providing solutions that are efficient, effective, and tailored to their specific needs. We work with businesses of all sizes, from startups to large corporations, and we have a proven track record of delivering results.</p>

                            <p>If you're looking for a software consultancy company that can help your business succeed, look no further than MARKO. Contact us today to learn more about our services and how we can help your business achieve its goals.</p>

                            <div className="read-more-btn mt--50">
                                <Link className="btn-default btn-icon" to="#">Learn More <i className="icon"><FiArrowRight /></i></Link>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 mt_md--30 mt_sm--30">
                        <AccordionOne customStyle="" />
                    </div>
                </div>
            </div>                        
        </div>
    )
}
export default mission;
