import PropTypes from "prop-types";
import React from 'react'
import {Helmet} from "react-helmet";

const SEO = ( {title} ) => {
    return (
        <>
            <Helmet>
            <meta charSet="utf-8" />
            <title>{title} | Marko</title>
            {/* <meta name="robots" content="noindex, follow" /> */}
            <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
            <meta name="description" content="Innovative tech solutions for businesses and individuals. Our expert team offers custom software development and IT consulting services." />
            <link rel="preload" href="https://www.itmarko.com/"/>
            <link rel="canonical" href="https://www.itmarko.com/"/>
            <link rel="preconnect" href="https://www.itmarko.com/"/>
            <meta name="csrf-param" content="_csrf-app-net" />
            <meta property="og:title" content="IT Consulting Services - IT Consulting Company | marko"/>
            <meta property="og:description" content="Itmarko IT consulting services rely on our company’s decade-long expertise and the extensive experience in developing bespoke software products. Our IT solutions consulting helped Fortune 200+ companies and established, world-class brands. Enlist the best minds at Itmarko now." />
            <meta property="og:image:type" content="image/jpeg" />
            <meta property="og:image:width" content="1920" />
            <meta property="og:image:height" content="1280" />
            <meta property="og:locale" content="en_US" />
            <meta property="og:url" content="https://www.itmarko.com/consulting-services" />
            <meta property="og:site_name" content="Itmarko IN" />
            <meta property="og:type" content="website" />
            <meta name="twitter:title" content="IT Consulting Services - IT Consulting Company | marko" />
            <meta name="twitter:description" content="Marko IT consulting services rely on our company’s decade-long expertise and the extensive experience in developing bespoke software products. Our IT solutions consulting helped Fortune 200+ companies and established, world-class brands. Enlist the best minds at itmarko now."/>
            <meta name="twitter:url" content="https://www.itmarko.com/consulting-services" />
            <meta name="twitter:domain" content="Itmarko IN" />
            <meta name="twitter:card" content="summary" />
            </Helmet>
        </>
    )
}
SEO.propTypes = {
    title: PropTypes.string
};

export default SEO;


