import React from 'react'
import SEO from '../../../common/SEO'
import Layout from '../../../common/Layout'
import Typed from 'react-typed'
import Separator from '../../separator/Separator'
import SectionTitle from '../../sectionTitle/SectionTitle'
import ServiceOne from '../../service/ServiceOne'
const Services=()=> {
  return (
    <>
        <SEO title="All Services"/>
        <Layout>
                <div className="main-content">
                    <div className="slider-area slider-style-5 bg-overlay-solid height-850 bg_image" data-black-overlay="3" style={{backgroundImage: `url(${process.env.PUBLIC_URL}/images/bg/bg-image-4.jpg)`}}>
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="inner text-start">
                                            <h4 className="subtitle">MARKO</h4>
                                            <h1 className="title theme-gradient display-two">
                                            <Typed
                                              strings={[
                                                "Services",
                                            ]}
                                            typeSpeed={80}
                                            backSpeed={5}
                                            backDelay={1000}
                                            loop
                                            />
                                            </h1>
                                            <p className="description">Our comprehensive range of business and technology <br /> solutions enables digital transformation, drives innovation, <br /> and fuels growth for companies globally.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                    </div>
                    {/* End Slider area  */} 
                    <Separator />
                    {/* Start Service Area  */}
                    <div className="rn-service-area rn-section-gap ">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <SectionTitle
                                        textAlign = "text-center"
                                        radiusRounded = ""
                                        subtitle = "What we can do for you"
                                        title = "Services provide for you."
                                        description = ""
                                     />
                                </div>
                            </div>
                            <ServiceOne 
                                serviceStyle = "service__style--1 bg-color-blackest radius mt--25 rbt-border "
                                textAlign = "text-center"
                             />
                        </div>
                    </div>
                    {/* End Service Area  */}

                    
                </div>
        </Layout>
    </>
  )
}

export default Services