import React from 'react';
import {Link} from "react-router-dom";
import { FiArrowRight  } from "react-icons/fi";
import ScrollAnimation from "react-animate-on-scroll";
const Nav = () => {
    return (
        <ul className="mainmenu">
            <li className="has-droupdown"><Link to="/">Home</Link>
            </li>
            
            <li><Link to="/about-us">About</Link></li>

            <li className="with-megamenu"><Link to="#">Services</Link>
                <div className="rn-megamenu">
                    <div className="wrapper">
                        <div className="row row--0">
                            <div className="col-lg-3 single-mega-item">
                                <ul className="mega-menu-item">
                                    <li><Link to="/artificial-intelligence">Artificial Intelligence</Link></li>
                                    <li><Link to="/data-and-analytics">Data and Analytics</Link></li>
                                    <li><Link to="/experience">Experience</Link></li>
                                    <li><Link to="/cloud-services">Cloud</Link></li>
                                    <li><Link to="/technology-services">Technology</Link></li>
                                    {/* <li><Link to="/blog-grid">Blog Grid</Link></li>
                                    <li><Link to="/service">Service</Link></li> */}
                                </ul>
                            </div>
                            <div className="col-lg-3 single-mega-item">
                                <ul className="mega-menu-item">
                                    <li><Link to="/error">Application</Link></li>
                                    <li><Link to="/business-process">Business Process</Link></li>
                                    <li><Link to="/business-solution">Business Solution</Link></li>
                                    <li><Link to="/consulting-services">Consulting</Link></li>
                                    <li><Link to="/design-and-experience">Design & Experience</Link></li>
                                    <li><Link to="/automation-services">Automation</Link></li>
                                    {/* <li><Link to="/split">Split Section</Link></li> */}
                                </ul>
                            </div>
                            <div className="col-lg-3 single-mega-item">
                                <ul className="mega-menu-item">
                                    <li><Link to="/cybersecurity-services">Cybersecurity</Link></li>
                                    <li><Link to="/digital-services">Digital Marketing</Link></li>
                                    <li><Link to="#">Web App Development</Link></li>
                                    <li><Link to="#">Web Development</Link></li>
                                    <li><Link to="#">Software Development</Link></li>
                                    <li><Link to="#">Andriod IOS Development</Link></li>
                                    {/* <li><Link to="/error">404</Link></li> */}
                                </ul>
                            </div>
                            <div className="col-lg-3 single-mega-item">
                                <ul className="mega-menu-item">
                                    <li><Link to="/error">IoT and Digital Engineering<span className="rn-badge-card">Comming</span></Link></li>
                                    <li><Link to="/cognitive-business-operations">Cognitive Business Operations </Link></li>
                                    <li><Link to="/sustainability-services">Sustainability Services</Link></li>
                                    <li><Link to="/enterprise-solutions-services">Enterprise Solutions</Link></li>
                                    {/* <li><Link to="/error">404 Page</Link></li><br/> */}
                                    <ScrollAnimation 
                                    animateIn="fadeInUp"
                                    animateOut="fadeInOut"
                                    animateOnce={true}
                                    className="button-group">
                                        <Link className="btn-default btn-small btn-icon" to="/services">All Service <i className="icon"><FiArrowRight /></i></Link>
                                    </ScrollAnimation>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </li>

            <li className="has-droupdown"><Link to="/blog">Blog</Link>
                {/* <ul className="submenu">
                    <li><Link to="/blog-grid">Blog Grid</Link></li>
                    <li><Link to="/blog-grid-sidebar">Blog Grid Sidebar</Link></li>
                    <li><Link to="/blog-list-view">Blog List View</Link></li>
                    <li><Link to="/blog-list-sidebar">Blog List View Sidebar</Link></li>
                </ul> */}
            </li>

            <li className="has-droupdown"><Link to="/portfolio-detail">Portfolio</Link>
                {/* <ul className="submenu">
                    <li><Link to="/portfolio">Portfolio Default</Link></li>
                    <li><Link to="/portfolio-three-column">Portfolio Three Column</Link></li>
                    <li><Link to="/portfolio-full-width">Portfolio Full Width</Link></li>
                    <li><Link to="/portfolio-grid-layout">Portfolio Grid Layout</Link></li>
                    <li><Link to="/portfolio-box-layout">Portfolio Box Layout</Link></li>
                </ul> */}
            </li>

            {/* <li className="has-droupdown"><Link to="/contact">Contact</Link></li> */}
        </ul>
    )
}
export default Nav;
