import React from 'react'

import { Card } from 'react-bootstrap';
import SEO from '../../../common/SEO';
import { FiArrowRight } from 'react-icons/fi';
import HeaderOne from '../../../common/header/HeaderOne';
import Layout from '../../../common/Layout';
import { Link } from 'react-router-dom';
import Separator from '../../separator/Separator';
import ScrollAnimation from 'react-animate-on-scroll';
import SectionTitle from '../../sectionTitle/SectionTitle';

import Typed from 'react-typed';
const  Technology=()=> {
  return (
    <>
        <SEO title={"Technology services"}/>
        <main className="page-wrapper">
            <HeaderOne btnStyle="btn-small btn-icon round" HeaderSTyle="header-transparent"/>
                <Layout>
                <div className="slider-area slider-style-5 bg-overlay-solid height-850 bg_image" data-black-overlay="3" style={{backgroundImage: `url(${process.env.PUBLIC_URL}/images/tec/tec-1.jpg)`}}>
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="inner text-start">
                                            <h4 className="subtitle">MARKO</h4>
                                            <h1 className="title theme-gradient display-two">
                                            <Typed
                                              strings={[
                                                "Technology <br/> services",
                                            ]}
                                            typeSpeed={80}
                                            backSpeed={5}
                                            backDelay={1000}
                                            loop
                                            />
                                            </h1>
                                            <p className="description">Develop a lean digital core and modernized tech stack for<br /> an agile, customer-centric, and cost-efficient enterprise.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                    </div>
                {/* End Slider area  */} 
                <Separator />
                {/* <div className="rn-company-mission-are rn-section-gap">
                            <div className="container">
                                <div className="row row--30">
                                    <div className="col-lg-7">
                                        <div className="mission-title">
                                            <h4 className="title">Modernize with cloud {" "}
                                            
                                            </h4>
                                            <p className="description">Marko's cloud transformation expertise comes from running operations and processes for hundreds of large enterprises. We'll help you make the move from migration to modernization.</p>
                                            <p className="description">We partner with cloud leaders – including Microsoft Azure, Amazon Web Services (AWS), and Google Cloud – and software-as-a-service (SaaS) providers to develop our unique suite of cloud-based products and services.</p>
                                            <p className="description">Let us help you manage cloud migration and modernization. With our cloud services, you'll find the support you need whatever your industry.</p>
                                            <p className="description">We build our cloud solutions on a deep understanding of how the cloud can connect people, processes, technology, and data. With this approach, we'll help your business digitally transform, while keeping employees productive and customers satisfied.</p>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 mt_md--30 mt_sm--30">
                                        <h4 className="title"> Our Impact</h4>
                                        <Card className='card bg-transparent' style={{ width: '40rem' }}>
                                            <Card.Img  src="./images/cloud/cloud-2.jpg" /> <br/>
                                        </Card>
                                            <h5>Bank of Montreal's data on cloud journey </h5>
                                            <p className="description">Description: Across every industry, we help enterprises keep their data secure and actionable with cloud. In this podcast, BMO shares its cloud journey.</p>
                                            <Link className="btn-read-more" to="#"><span>View More</span></Link>
                                    </div>
                                    
                                </div>
                            </div>
                        </div> */}
                        <Separator/>
                        {/* <div className='container'>
                            <div className='row'>
                                <div className='col-lg-12'>
                                    <div className="text-center">
                                        <SectionTitle
                                            textAlign = "text-center"
                                            radiusRounded = ""
                                            title = "Our Solutions"
                                            description = "Every business needs a different cloud strategy as every industry has unique requirements. We'll help you work out <br/> what's right for you. We build our solutions with industry, operational, and technology expertise to help you accelerate your journey to the cloud."
                                        />                                        
                                    </div>
                                    <SlipThree/>
                                    <div className="rn-splite-style bg-color-blackest">
                                        <div className="split-wrapper">
                                            <div className="row no-gutters radius-10 align-items-center">
                                                <div className="col-lg-12 col-xl-6 col-12">
                                                    <div className="thumbnail">
                                                        <img src="./images/cloud/cloud-3.jpg" alt="split Images" />
                                                    </div>
                                                </div>
                                                <div className="col-lg-12 col-xl-6 col-12">
                                                    <div className="split-inner">
                                                        <ScrollAnimation 
                                                        animateIn="fadeInUp"
                                                        animateOut="fadeInOut"
                                                        animateOnce={true}>
                                                            <h4 className="">Cloud advisory</h4>
                                                        </ScrollAnimation>
                                                        <ScrollAnimation 
                                                        animateIn="fadeInUp"
                                                        animateOut="fadeInOut"
                                                        animateOnce={true}>
                                                        <p className="description">We help clients establish a cloud strategy and advise on application and infrastructure modernization to boost business agility and maximize ROI.</p>
                                                        </ScrollAnimation>
                                                        <Link className="btn-default btn-icon" to="#"> Learn More<i className="icon"><FiArrowRight/></i></Link>
                                                        
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Separator /> */}

                        

                        <Separator/>
                        
                        <div className='container'>
                            <div className='row'>
                                <div className='col-lg-12'>
                                    <div className="text-center">
                                        <SectionTitle
                                            textAlign = "text-center"
                                            radiusRounded = ""
                                            title = "Why Marko"
                                            description = "If you think Marko is just about outsourcing, think again. We partner with clients to transform enterprises inside and out. Our industry, business, and operational expertise – backed by an extensive partner ecosystem – will give you a competitive advantage. Working with Marko, you'll drive efficiency and innovation without losing sight of business objectives and strategic priorities."
                                        />
                                        <div className="rn-blog-area rn-section-gap">
                                            <div className="content">
                                                <div className="row mt_dec--30">
                                                    <div className="col-sm rn-card m-4">
                                                        <div className="card bg-transparent border-0" >
                                                            <div className="card-body">
                                                            <ScrollAnimation 
                                                                animateIn="fadeInUp"
                                                                animateOut="fadeInOut"
                                                                animateOnce={true}><br />
                                                                    <h5 className="">IT infrastructure modernization services</h5>
                                                                </ScrollAnimation>
                                                                <ScrollAnimation 
                                                                animateIn="fadeInUp"
                                                                animateOut="fadeInOut"
                                                                animateOnce={true}>
                                                                <p className="description">IT infrastructure modernization services update and upgrade businesses' technology infrastructure for improved performance, scalability, security, and cost-effectiveness. </p>
                                                                </ScrollAnimation>
                                                                <Link className="btn-read-more" to="#"><span>Learn More</span></Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm rn-card  m-4">
                                                        <div className="card bg-transparent border-0" >
                                                            <div className="card-body">
                                                            <ScrollAnimation 
                                                                animateIn="fadeInUp"
                                                                animateOut="fadeInOut"
                                                                animateOnce={true}><br />
                                                                    <h5 className="">Enterprise Applications</h5>
                                                                </ScrollAnimation>
                                                                <ScrollAnimation 
                                                                animateIn="fadeInUp"
                                                                animateOut="fadeInOut"
                                                                animateOnce={true}>
                                                                <p className="description">Enterprise Applications services optimize enterprise-level software for businesses, including implementation, customization, training, support, maintenance, and upgrades.</p>
                                                                </ScrollAnimation>
                                                                <Link className="btn-read-more" to="#"><span>Learn More</span></Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className=" col-sm rn-card  m-4">
                                                        <div class="card bg-transparent border-0" >
                                                            <div class="card-body">
                                                            <ScrollAnimation 
                                                                animateIn="fadeInUp"
                                                                animateOut="fadeInOut"
                                                                animateOnce={true}><br />
                                                                    <h5 className="">Application Services</h5>
                                                                </ScrollAnimation>
                                                                <ScrollAnimation 
                                                                animateIn="fadeInUp"
                                                                animateOut="fadeInOut"
                                                                animateOnce={true}>
                                                                <p className="description">Application Services encompass application development, maintenance and support, integration, and optimization for businesses' software applications.</p>
                                                                </ScrollAnimation>
                                                                <Link className="btn-read-more" to="#"><span>Learn More</span></Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    
                                                </div>
                                            </div>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                      
                </Layout>
        </main>
    </>
  )
}

export default Technology;