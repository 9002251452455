import React from 'react'
import SEO from "../common/SEO";
import HeaderOne from '../common/header/HeaderOne';
import CopyrightTwo from '../common/footer/CopyrightTwo';
import FooterTwo from '../common/footer/FooterTwo';
import BrandThree from '../elements/brand/BrandThree';
import AboutFour from '../elements/about/AboutFour';
import SectionTitle from "../elements/sectionTitle/SectionTitle";
import CounterUpFour from "../elements/counterup/CounterUpFour";
// import TeamOne from '../elements/team/TeamOne';
import TeamFour from '../elements/team/TeamFour';
import TimelineTwo from "../elements/timeline/TimelineTwo";
import Separator from "../elements/separator/Separator";

const AboutUs = () => {
    return (
        <>
            <SEO title="About Us" />
            <main className="page-wrapper">
                {/* <HeaderTopNews /> */}
                <HeaderOne btnStyle="btn-small round btn-icon" HeaderSTyle="header-transparent" />

                {/* Start Slider Area  */}
                <div className="slider-area slider-style-1 height-850 bg_image" data-black-overlay="7" style={{backgroundImage: `url(${process.env.PUBLIC_URL}/images/bg/bg-image-12.jpg)`}}>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="inner pt--80 text-center">
                                    <div>
                                        <h3 className="rn-sub-badge"><span className="theme-gradient">Meet MARKO.</span></h3>
                                    </div>
                                    <h1 className="title display-one">Creating Digital Transformation for <br /> Your Business Work Batter.</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Slider Area  */}


                {/* Start Service Area  */}
                <div className="service-area rn-section-gapTop">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="content">
                                    <h3 className="title">Why we do what we do.</h3>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <p className="mb--10">As a software company, we are a team of experienced professionals who are passionate about creating innovative software solutions to help our clients achieve their goals. Our expertise spans a wide range of technologies, from web and mobile app development to cloud computing and artificial intelligence.</p>
                                <p className="mb--10">We pride ourselves on delivering high-quality software that is efficient, user-friendly, and tailored to the specific needs of our clients. We work closely with our clients to understand their business objectives and develop software solutions that meet their unique requirements.</p>
                                <p className="mb--10">At our core, we are committed to using the latest technologies and best practices to deliver software that is not only effective, but also scalable and adaptable to changing business needs. We value transparency, collaboration, and open communication with our clients, and strive to build long-term relationships based on trust and mutual respect.</p>
                                <p className="mb--10">Ultimately, who we are as a software company is defined by our commitment to excellence in everything we do, and our passion for helping our clients achieve their full potential through the power of software.</p>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Service Area  */}



                {/* Start Brand Area  */}
                <div className="rwt-brand-area pb--60 pt--30">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 mt--10">
                                <BrandThree brandStyle="brand-style-2" />
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Brand Area  */}


                <Separator />

                {/* Start Service Area  */}
                {/* <div className="rn-service-area rn-section-gap">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <SectionTitle
                                    textAlign = "text-center"
                                    radiusRounded = ""
                                    subtitle = "What we can do for you"
                                    title = "Services provide for you."
                                    description = "There are many variations of passages of Lorem Ipsum available, <br /> but the majority have suffered alteration."
                                    />
                            </div>
                        </div>
                        <ServiceOne 
                            serviceStyle = "service__style--1 bg-color-blackest radius mt--25"
                            textAlign = "text-center"
                            />
                    </div>
                </div> */}
                {/* End Service Area  */}

                <div className="rwt-timeline-area rn-section-gap">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                    <SectionTitle
                                        textAlign = "text-center"
                                        radiusRounded = ""
                                        subtitle = "OUR APPROACH"
                                        title = "Our Working Process."
                                    />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-10 offset-lg-1 mt--50">
                                <TimelineTwo classVar=""  />
                            </div>
                        </div>
                    </div>
                </div>

                <Separator />

                <AboutFour image="./images/about/contact-image.jpg" />
                
                {/* Start Elements Area  */}
                <div className="rwt-counterup-area pb--100">
                    <div className="container mt_dec--30">
                        <CounterUpFour column="col-lg-3 col-md-6 col-sm-6 col-12" counterStyle="counter-style-4" textALign="text-center" />   
                    </div>
                </div>
                {/* End Elements Area  */}

                <Separator />

                {/* Start Elements Area  */}
                <div className="rwt-team-area rn-section-gap">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12 mb--15">
                                        <SectionTitle
                                            textAlign = "text-center"
                                            radiusRounded = ""
                                            subtitle = "Our People."
                                            title = "Company Peoples."
                                            description = ""
                                        />
                                </div>
                            </div>
                            <TeamFour column="col-lg-6 col-xl-3 col-md-6 col-12 mt--30" teamStyle="team-style-three" />
                        </div>
                    </div>
                <Separator />
                {/* <div className="rwt-team-area rn-section-gap">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                    <SectionTitle
                                        textAlign = "text-center"
                                        radiusRounded = ""
                                        subtitle = "Our leadership."
                                        title = "Company leaders."
                                         description = ""
                                    />
                            </div>
                        </div>
                        <TeamOne column="col-lg-4 col-md-6 col-12 mt--30" teamStyle="team-style-default" />
                    </div>
                </div> */}
                {/* End Elements Area  */}






                <FooterTwo />
                <CopyrightTwo />
            </main>
        </>
    )
}

export default AboutUs;
