import React from 'react'

import { Card } from 'react-bootstrap';
import SEO from '../../../common/SEO';
import { FiArrowRight } from 'react-icons/fi';
import HeaderOne from '../../../common/header/HeaderOne';
import Layout from '../../../common/Layout';
import { Link } from 'react-router-dom';
import Separator from '../../separator/Separator';
import ScrollAnimation from 'react-animate-on-scroll';
import SectionTitle from '../../sectionTitle/SectionTitle';
import Typed from 'react-typed';
const  Sustsinsblity=()=> {
  return (
    <>
        <SEO title={"Cloud Technology"}/>
        <main className="page-wrapper">
            <HeaderOne btnStyle="btn-small btn-icon round" HeaderSTyle="header-transparent"/>
                <Layout>
                <div className="slider-area slider-style-5 bg-overlay-solid height-850 bg_image" data-black-overlay="3" style={{backgroundImage: `url(${process.env.PUBLIC_URL}/images/sust/bg-image-1.jpg)`}}>
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="inner text-start">
                                            <h4 className="subtitle">MARKO</h4>
                                            <h1 className="title theme-gradient display-two">
                                            <Typed
                                              strings={[
                                                "Sustainability <br/> services",
                                            ]}
                                            typeSpeed={80}
                                            backSpeed={5}
                                            backDelay={1000}
                                            loop
                                            />
                                            </h1>
                                            <p className="description">Build the next lever of transformative impact for your organization</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                    </div>
                {/* End Slider area  */} 
                <Separator />
                <div className="rn-company-mission-are rn-section-gap">
                            <div className="container">
                                <div className="row row--30">
                                    <div className="col-lg-7">
                                        <div className="mission-title">
                                            <h4 className="title">Creating Environmental, Social, and Governance (ESG) Impact and Achieving Business Success: A Winning Approach {" "}
                                            </h4>
                                            <p className="description">Embracing ESG responsibilities as a core business strategy unlocks a new competitive advantage. By addressing customer, employee, and regulator demands, organizations protect the planet, improve livelihoods, and become leaders in the next generation of businesses that work for the betterment of people and the planet.</p>
                                            <p className="description">To drive meaningful change for people and communities, organizations must take a holistic approach to the ESG challenge, considering multiple dimensions. Leveraging digital technologies and analytics, businesses can uncover environmental and social opportunities, enabling them to integrate long-term strategies and measure their impact accurately.</p>
                                            <p className="description">With our extensive industry expertise and advanced digital and analytics capabilities, we empower companies to make meaningful and sustainable progress for their businesses and the world.</p>
                                            <p className="description">Read on to find out how.</p>

                                            
                                        </div>
                                    </div>
                                    <div className="col-lg-4 mt_md--30 mt_sm--30">
                                        <h4 className="title"> Our Impact</h4>
                                        <Card className='card bg-transparent' style={{ width: '40rem' }}>
                                            <Card.Img  src="./images/sust/impact-1.jpg" /> <br/>
                                        </Card>
                                            <h5>Reimagine your approach to deploying and managing ESG programs. </h5>
                                            <p className="description">Organizations across the globe are intensifying their ESG initiatives to create positive impacts. Discover how Marko can assist in implementing scalable and effective sustainability programs.</p>
                                            <Link className="btn-read-more" to="#"><span>View More</span></Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Separator/>
                        <div className='container'>
                            <div className='row'>
                                <div className='col-lg-12'>
                                    <div className="text-center">
                                        <SectionTitle
                                            textAlign = "text-center"
                                            radiusRounded = ""
                                            title = "Our Solutions"
                                            description = "Our comprehensive solutions, ranging from strategic roadmaps to robust reporting and responsible<br/> sourcing practices, empower you to integrate ESG principles into the fabric of your organization."
                                        />                                        
                                    </div>
                                    {/* <SlipThree/> */}
                                    <div className="rn-splite-style bg-color-blackest">
                                        <div className="split-wrapper">
                                            <div className="row no-gutters radius-10 align-items-center">
                                                <div className="col-lg-12 col-xl-6 col-12">
                                                    <div className="thumbnail">
                                                        <img src="./images/sust/bg-image-2.jpg" alt="split Images" />
                                                    </div>
                                                </div>
                                                <div className="col-lg-12 col-xl-6 col-12">
                                                    <div className="split-inner">
                                                        <ScrollAnimation 
                                                        animateIn="fadeInUp"
                                                        animateOut="fadeInOut"
                                                        animateOnce={true}>
                                                        <h4 className="">Our ESG commitment</h4>
                                                        </ScrollAnimation>
                                                        <ScrollAnimation 
                                                        animateIn="fadeInUp"
                                                        animateOut="fadeInOut"
                                                        animateOnce={true}>
                                                        <p className="description"></p>
                                                        </ScrollAnimation>
                                                        <Link className="btn-default btn-icon" to="#"> Learn More<i className="icon"><FiArrowRight/></i></Link>                                                        
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Separator />                        
                        <div className='container'>
                            <div className='row'>
                                <div className='col-lg-12'>
                                    <div className="text-center">
                                        <SectionTitle
                                            textAlign = "text-center"
                                            radiusRounded = ""
                                            title = "Why Marko"
                                            description = "With our expertise in data, analytics, reporting, and compliance, we excel in transforming processes and<br/> managing change. When combined with our unwavering commitment to driving positive impacts on businesses,<br/> communities, and the planet, our ESG strategy becomes a winning formula for success."
                                        />
                                        <div className="rn-blog-area rn-section-gap">
                                            <div className="content">
                                                <div className="row mt_dec--30">
                                                    <div className="col-sm rn-card m-4">
                                                        <div className="card bg-transparent border-0" >
                                                            <div className="card-body">
                                                                <ScrollAnimation 
                                                                    animateIn="fadeInUp"
                                                                    animateOut="fadeInOut"
                                                                    animateOnce={true}><br />
                                                                    <h5 className="">Modernization</h5>
                                                                    </ScrollAnimation>
                                                                    <ScrollAnimation 
                                                                    animateIn="fadeInUp"
                                                                    animateOut="fadeInOut"
                                                                    animateOnce={true}>
                                                                    <p className="description">To make the most of the cloud, you must modernize. It's time to restructure your legacy infrastructure, applications, and data.</p>
                                                                </ScrollAnimation>
                                                                <Link className="btn-read-more" to="#"><span>Learn More</span></Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm rn-card  m-4">
                                                        <div className="card bg-transparent border-0" >
                                                            <div className="card-body">
                                                            <ScrollAnimation 
                                                                animateIn="fadeInUp"
                                                                animateOut="fadeInOut"
                                                                animateOnce={true}><br />
                                                                <h5 className="">Security</h5>
                                                                </ScrollAnimation>
                                                                <ScrollAnimation 
                                                                animateIn="fadeInUp"
                                                                animateOut="fadeInOut"
                                                                animateOnce={true}>
                                                                <p className="description">Protect your investment. We have a trusted risk and compliance capability that covers all aspects of cloud security and governance.</p>
                                                                </ScrollAnimation>
                                                                <Link className="btn-read-more" to="#"><span>Learn More</span></Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className=" col-sm rn-card  m-4">
                                                        <div class="card bg-transparent border-0" >
                                                            <div class="card-body">
                                                            <ScrollAnimation 
                                                                animateIn="fadeInUp"
                                                                animateOut="fadeInOut"
                                                                animateOnce={true}><br />
                                                                <h5 className="">CloudSmart Designer</h5>
                                                                </ScrollAnimation>
                                                                <ScrollAnimation 
                                                                animateIn="fadeInUp"
                                                                animateOut="fadeInOut"
                                                                animateOnce={true}>
                                                                <p className="description">CloudSmart Designer helps business leaders provision, automate, and scale their deployments across a variety of cloud environments.</p>
                                                                </ScrollAnimation>
                                                                <Link className="btn-read-more" to="#"><span>Learn More</span></Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    
                                                </div>
                                            </div>
                                            <div className="content pt-5">
                                                <div className="row mt_dec--30">
                                                    <div className="col-sm rn-card m-4">
                                                        <div className="card bg-transparent border-0" >
                                                            <div className="card-body">
                                                            <ScrollAnimation 
                                                                animateIn="fadeInUp"
                                                                animateOut="fadeInOut"
                                                                animateOnce={true}>
                                                                <br />
                                                                <h5 className="">CloudSmart Manager</h5>
                                                                </ScrollAnimation>
                                                                <ScrollAnimation 
                                                                animateIn="fadeInUp"
                                                                animateOut="fadeInOut"
                                                                animateOnce={true}>
                                                                <p className="description">Marko's CloudSmart Manager helps businesses understand cloud costs, transform workloads, and govern spending effectively.</p>
                                                                </ScrollAnimation>
                                                                <Link className="btn-read-more" to="#"><span>Learn More</span></Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm rn-card  m-4">
                                                        <div className="card bg-transparent border-0" >
                                                            <div className="card-body">
                                                            <ScrollAnimation 
                                                                animateIn="fadeInUp"
                                                                animateOut="fadeInOut"
                                                                animateOnce={true}><br />
                                                                <h5 className="">Migration</h5>
                                                                </ScrollAnimation>
                                                                <ScrollAnimation 
                                                                animateIn="fadeInUp"
                                                                animateOut="fadeInOut"
                                                                animateOnce={true}>
                                                                <p className="description">To benefit from what the cloud has to offer, you need to move to it. Let us help you accelerate your cloud migration.</p>
                                                                </ScrollAnimation>
                                                                <Link className="btn-read-more" to="#"><span>View More</span></Link>
                                                            </div>
                                                        </div>
                                                    </div>                                               
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>                     
                </Layout>
        </main>
    </>
  )
}

export default Sustsinsblity;