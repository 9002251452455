import React from 'react';
import CountUp from 'react-countup';
import TrackVisibility from "react-on-screen";
import ScrollAnimation from "react-animate-on-scroll";
import { Link } from 'react-router-dom';
import { FiArrowRight } from 'react-icons/fi';

const SlipThree = () => {
    return (
        <div className="rn-splite-style bg-color-blackest">
            <div className="split-wrapper">
                <div className="row no-gutters radius-10 align-items-center">
                    <div className="col-lg-12 col-xl-6 col-12">
                        <div className="thumbnail">
                            <img src="./images/ai/ai-3.jpg" alt="split Images" />
                        </div>
                    </div>
                    <div className="col-lg-12 col-xl-6 col-12">
                        <div className="split-inner">
                            <ScrollAnimation 
                            animateIn="fadeInUp"
                            animateOut="fadeInOut"
                            animateOnce={true}>
                                <h4 className="">AI/ML Advisory and strategy consulting</h4>
                            </ScrollAnimation>
                            <ScrollAnimation 
                            animateIn="fadeInUp"
                            animateOut="fadeInOut"
                            animateOnce={true}>
                            <p className="description">Discover high-ROI opportunities by developing a clear implementation roadmap, using strategies and techniques that result in long-term competitive advantages.</p>
                            </ScrollAnimation>
                            <Link className="btn-default btn-icon" to="#"> Learn More<i className="icon"><FiArrowRight/></i></Link>
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default SlipThree;