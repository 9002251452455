import React from 'react';
import SEO from '../../../common/SEO';
import HeaderOne from '../../../common/header/HeaderOne';
import Layout from '../../../common/Layout';
import Separator from '../../separator/Separator';
import { Card } from 'react-bootstrap';
import SectionTitle from '../../sectionTitle/SectionTitle';
// import SlipThree from '../../split/SlipThree';
import { FiArrowRight } from 'react-icons/fi';
import ScrollAnimation from 'react-animate-on-scroll';
import { Link } from 'react-router-dom';
import BlogList from '../../../components/blog/itemProp/BlogList';
import BlogClassicData from '../../../data/blog/BlogList.json';
var BlogListData = BlogClassicData.slice(0, 3);
const Experience =({ data, StyleVar })=> {
  return (
    <>
        <SEO title={"Customer Experience Services"}/>
        <main className="page-wrapper">
            <HeaderOne btnStyle="btn-small btn-icon round" HeaderSTyle="header-transparent"/>
                <Layout>
                <div className="slider-area slider-style-5 bg-overlay-solid height-850 bg_image" data-black-overlay="3" style={{backgroundImage: `url(${process.env.PUBLIC_URL}/images/exp/exp-1.jpg)`}}>
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="inner text-start">
                                            <h4 className="subtitle">MARKO</h4>
                                            <h1 className="title theme-gradient">Customer<br /> experience <br /> management<br /> services</h1>
                                            <p className="description">Put the needs of employees, customers, and partners at the<br /> heart of your digital transformation strategy</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                    </div>
                        {/* End Slider Area  */}
                        <Separator />
                        <div className="rn-company-mission-are rn-section-gap">
                            <div className="container">
                                <div className="row row--30">
                                    <div className="col-lg-7">
                                        <div className="mission-title">
                                            <h4 className="title">Create memorable experiences to help your business thrive<br /> {" "}
                                            
                                            </h4>
                                            <p className="description">Looking for customer experience transformation? You've come to the right place.</p>
                                            <p className="description">Our customer experience management services seamlessly connect people, processes, data, and technology to help enterprises successfully attract and retain customers.</p>
                                            <p className="description">Digital experience transformation extends to the employee experience too. And with our industry and operational expertise, we'll manage everything with the unique needs of your business in mind.</p>
                                            <p className="description">Whatever challenges you're facing, we'll tailor our approach so you can create deeper connections with employees, customers, and partners.</p>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 mt_md--30 mt_sm--30">
                                        <h4 className="title"> Our Impact</h4>
                                        <Card className='card bg-transparent' style={{ width: '40rem' }}>
                                            <Card.Img  src="./images/exp/exp-2.jpg" /> <br/>
                                        </Card>
                                            <h5>User research brings a global business closer to its customers </h5>
                                            <p className="description">See how standardized insights and customer journey transformation help this business anticipate the needs of its customers in a competitive market. </p>
                                            <Link className="btn-read-more" to="#"><span>Get The Full Story</span></Link>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                        <Separator/>
                        <div className='container'>
                            <div className='row'>
                                <div className='col-lg-12'>
                                    <div className="text-center">
                                        <SectionTitle
                                            textAlign = "text-center"
                                            radiusRounded = ""
                                            title = "Our Solutions"
                                            description = "We can create a world-class customer experience (CX) with our CX transformation strategy rooted in industry expertise. <br/>Our multidisciplinary teams help companies reimagine experiences for employees, customers, and partners to uncover <br/>a competitive advantage. Let us help your business thrive."
                                        />                                        
                                    </div>
                                    {/* <SlipThree/> */}
                                    <div className="rn-splite-style bg-color-blackest">
                                        <div className="split-wrapper">
                                            <div className="row no-gutters radius-10 align-items-center">
                                                <div className="col-lg-12 col-xl-6 col-12">
                                                    <div className="thumbnail">
                                                        <img src="./images/exp/exp-3.jpg" alt="split Images" />
                                                    </div>
                                                </div>
                                                <div className="col-lg-12 col-xl-6 col-12">
                                                    <div className="split-inner">
                                                        <ScrollAnimation 
                                                        animateIn="fadeInUp"
                                                        animateOut="fadeInOut"
                                                        animateOnce={true}>
                                                            <h4 className="">Custom applications</h4>
                                                        </ScrollAnimation>
                                                        <ScrollAnimation 
                                                        animateIn="fadeInUp"
                                                        animateOut="fadeInOut"
                                                        animateOnce={true}>
                                                        <p className="description">Your business is unique, and so you need unique applications. From concept to design and engineering let us help you build what your business needs.</p>
                                                        </ScrollAnimation>
                                                        <Link className="btn-default btn-icon" to="#"> Learn More<i className="icon"><FiArrowRight/></i></Link>
                                                        
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Separator />

                        

                        <Separator/>
                        
                        <div className='container'>
                            <div className='row'>
                                <div className='col-lg-12'>
                                    <div className="text-center">
                                        <SectionTitle
                                            textAlign = "text-center"
                                            radiusRounded = ""
                                            title = "Why Marko"
                                            description = "We redefine how organizations operate in the digital age. From transforming business models using automation to building<br /> new  solutions with artificial intelligence and machine learning, we use technology to make the world work better for people. <br/>With Rightpoint, a Marko company, we help leading brands unlock competitive advantage with experience."
                                        />
                                        <div className="rn-blog-area rn-section-gap">
                                            <div className="content">
                                                <div className="row mt_dec--30">
                                                    <div className="col-sm rn-card m-4">
                                                        <div className="card bg-transparent border-0" >
                                                            <div className="card-body">
                                                            <ScrollAnimation 
                                                                animateIn="fadeInUp"
                                                                animateOut="fadeInOut"
                                                                animateOnce={true}><br />
                                                                    <h5 className="">Digital commerce strategy</h5>
                                                                </ScrollAnimation>
                                                                <ScrollAnimation 
                                                                animateIn="fadeInUp"
                                                                animateOut="fadeInOut"
                                                                animateOnce={true}>
                                                                <p className="description">E-commerce platforms, website design, digital marketing, customer experience, data analytics, omnichannel integration, security, and continuous improvement for online success.</p>
                                                                </ScrollAnimation>
                                                                <Link className="btn-read-more" to="#"><span>Learn More</span></Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm rn-card  m-4">
                                                        <div className="card bg-transparent border-0" >
                                                            <div className="card-body">
                                                            <ScrollAnimation 
                                                                animateIn="fadeInUp"
                                                                animateOut="fadeInOut"
                                                                animateOnce={true}><br />
                                                                    <h5 className="">Digital commerce technology</h5>
                                                                </ScrollAnimation>
                                                                <ScrollAnimation 
                                                                animateIn="fadeInUp"
                                                                animateOut="fadeInOut"
                                                                animateOnce={true}>
                                                                <p className="description">E-commerce platforms, payment gateways, CMS, CRM systems, digital marketing tools, mCommerce platforms, OMS, and AI/ML technologies for online success.</p>
                                                                </ScrollAnimation>
                                                                <Link className="btn-read-more" to="#"><span>Learn More</span></Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className=" col-sm rn-card  m-4">
                                                        <div class="card bg-transparent border-0" >
                                                            <div class="card-body">
                                                            <ScrollAnimation 
                                                                animateIn="fadeInUp"
                                                                animateOut="fadeInOut"
                                                                animateOnce={true}><br />
                                                                    <h5 className="">Emerging technologies</h5>
                                                                </ScrollAnimation>
                                                                <ScrollAnimation 
                                                                animateIn="fadeInUp"
                                                                animateOut="fadeInOut"
                                                                animateOnce={true}>
                                                                <p className="description">From immersive virtual experiences to augmented reality, we help companies rethink what's possible and create customized experiences.</p>
                                                                </ScrollAnimation>
                                                                <Link className="btn-read-more" to="#"><span>Learn More</span></Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    
                                                </div>
                                            </div>
                                            <div className="content pt-5">
                                                <div className="row mt_dec--30">
                                                    <div className="col-sm rn-card m-4">
                                                        <div className="card bg-transparent border-0" >
                                                            <div className="card-body">
                                                            <ScrollAnimation 
                                                                animateIn="fadeInUp"
                                                                animateOut="fadeInOut"
                                                                animateOnce={true}>
                                                                    <br />
                                                                    <h5 className="">Mobile commerce strategy and technology</h5>
                                                                </ScrollAnimation>
                                                                <ScrollAnimation 
                                                                animateIn="fadeInUp"
                                                                animateOut="fadeInOut"
                                                                animateOnce={true}>
                                                                <p className="description">Using our experience in mobile commerce, we can help with platform assessments, complex integrations, and perform analytics tracking.</p>
                                                                </ScrollAnimation>
                                                                <Link className="btn-read-more" to="#"><span>Learn More</span></Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm rn-card  m-4">
                                                        <div className="card bg-transparent border-0" >
                                                            <div className="card-body">
                                                            <ScrollAnimation 
                                                                animateIn="fadeInUp"
                                                                animateOut="fadeInOut"
                                                                animateOnce={true}><br />
                                                                    <h5 className="">Platform modernization</h5>
                                                                </ScrollAnimation>
                                                                <ScrollAnimation 
                                                                animateIn="fadeInUp"
                                                                animateOut="fadeInOut"
                                                                animateOnce={true}>
                                                                <p className="description">When you modernize your customer-facing platforms, you modernize the customer experience too.</p>
                                                                </ScrollAnimation>
                                                                <Link className="btn-read-more" to="#"><span>View More</span></Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className=" col-sm rn-card  m-4">
                                                        <div class="card bg-transparent border-0" >
                                                            <div class="card-body">
                                                            <ScrollAnimation 
                                                                animateIn="fadeInUp"
                                                                animateOut="fadeInOut"
                                                                animateOnce={true}><br />
                                                                    <h5 className="">Service design</h5>
                                                                </ScrollAnimation>
                                                                <ScrollAnimation 
                                                                animateIn="fadeInUp"
                                                                animateOut="fadeInOut"
                                                                animateOnce={true}>
                                                                <p className="description">Service design helps companies reimagine the way their business works from the front office to the back office.</p>
                                                                </ScrollAnimation>
                                                                <Link className="btn-read-more" to="#"><span>View More</span></Link>
                                                            </div>
                                                        </div>
                                                    </div>                                                    
                                                </div>
                                            </div>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Separator />
                        
                </Layout>
        </main>
    </>
  )
}

export default Experience