import React from 'react';
import {HashRouter as Router, Switch, Route} from 'react-router-dom';
import PageScrollTop from './components/pageToTop/PageScrollTop'

// Pages import Here 
import Home from "./pages/Home";
import Consulting from "./pages/Consulting";
import Startup from "./pages/Startup";


import CategoryList from "./pages/CategoryList";
import TagList from "./pages/TagList";
import Author from "./pages/Author";
import AboutUs from "./pages/AboutUs";

// Elements import Here 

import Portfolio from "./elements/portfolio/Portfolio";
import PortfolioFullWidth from "./elements/portfolio/PortfolioFullWidth";
import PortfolioDetails from "./pages/PortfolioDetails";
import Team from "./elements/team/Team";
import Testimonial from "./elements/testimonial/Testimonial";
import Gallery from "./elements/gallery/Gallery";
import Contact from "./elements/contact/Contact";

import BlogGridSidebar from "./components/blog/BlogGridSidebar";
import BlogDetails from "./pages/BlogDetails";

import Error from "./pages/Error";

//Service pages import here

import Artificial from './elements/technology/ai/artificial';
import Automation from './elements/technology/automation/Automation';
import BusinessProcess from './elements/technology/business/BusinessProcess';
import BusinessSolutions from './elements/technology/business/BusinessSolutions';
import Cloud from './elements/technology/cloud/Cloud';
import CognitiveBusiness from './elements/technology/cognitivebusiness/CognitiveBusiness';
import Cybersecurity from './elements/technology/cybersecurity/Cybersecurity';
import DataAnalytics from './elements/technology/dataAnalytics/DataAnalytics';
import DesignExperience from './elements/technology/designexperiance/DesignExperience';
import DigitalMarketing from './elements/technology/digitalmarketing/DigitalMarketing';
import Experience from './elements/technology/experience/Experience';
import Sustsinsblity from './elements/technology/sunsb/Sustainability';
import Services from './elements/technology/service/Services';
import Technology from './elements/technology/technology/technology';
//policy and condition 
import Privecy from './elements/technology/policyAndCond/Privecy';
import TermsAndConditions from "./elements/technology/policyAndCond/TermsandConditions";

// Import Css Here 
import './assets/scss/style.scss';
import EnterpriseSolutions from './elements/technology/enterpriseSolutions/EnterpriseSolutions';


const App = () => {
    return (
        <Router>
            <PageScrollTop>
                <Switch>
                
                    <Route path={`${process.env.PUBLIC_URL + "/"}`} exact component={Home}/>
                    <Route path={`${process.env.PUBLIC_URL + "/startup"}`} exact component={Startup}/>

                    {/* Blog Part  */}
                    <Route path={`${process.env.PUBLIC_URL + "/blog"}`} exact component={BlogGridSidebar}/>
                    <Route path={`${process.env.PUBLIC_URL + "/blog-details/:id"}`} exact component={BlogDetails}/>
                    <Route path={`${process.env.PUBLIC_URL + "/category/:slug"}`} exact component={CategoryList}/>
                    <Route path={`${process.env.PUBLIC_URL + "/archive/:slug"}`} exact component={Author}/>
                    <Route path={`${process.env.PUBLIC_URL + "/tag/:slug"}`} exact component={TagList}/>
                    <Route path={`${process.env.PUBLIC_URL + "/team"}`} exact component={Team}/>
                    <Route path={`${process.env.PUBLIC_URL + "/testimonial"}`} exact component={Testimonial}/>
                    <Route path={`${process.env.PUBLIC_URL + "/gallery"}`} exact component={Gallery}/>
                    <Route path={`${process.env.PUBLIC_URL + "/contact-us"}`} exact component={Contact}/>
                    <Route path={`${process.env.PUBLIC_URL + "/about-us"}`} exact component={AboutUs}/>
                                       
                    <Route path={`${process.env.PUBLIC_URL + "/portfolio-detail"}`} exact component={PortfolioFullWidth}/>
                    <Route path={`${process.env.PUBLIC_URL + "/portfolio-details/:id"}`} exact component={PortfolioDetails}/>
                    <Route path={`${process.env.PUBLIC_URL + "/artificial-intelligence"}`} exact component={Artificial}/>
                    <Route path={`${process.env.PUBLIC_URL + "/enterprise-solutions-services"}`} exact component={EnterpriseSolutions}/>

                    <Route path={`${process.env.PUBLIC_URL + "/data-and-analytics"}`} exact component={DataAnalytics}/>
                    <Route path={`${process.env.PUBLIC_URL + "/experience"}`} exact component={Experience}/>
                    <Route path={`${process.env.PUBLIC_URL + "/cloud-services"}`} exact component={Cloud}/>
                    <Route path={`${process.env.PUBLIC_URL + "/technology-services"}`} exact component={Technology}/>
                    <Route path={`${process.env.PUBLIC_URL + "/automation-services"}`} exact component={Automation}/>
                    <Route path={`${process.env.PUBLIC_URL + "/sustainability-services"}`} exact component={Sustsinsblity}/>
                    <Route path={`${process.env.PUBLIC_URL + "/consulting-services"}`} exact component={Consulting}/>
                    <Route path={`${process.env.PUBLIC_URL + "/cybersecurity-services"}`} exact component={Cybersecurity}/>
                    <Route path={`${process.env.PUBLIC_URL + "/cognitive-business-operations"}`} exact component={CognitiveBusiness}/>
                    <Route path={`${process.env.PUBLIC_URL + "/design-and-experience"}`} exact component={DesignExperience}/>
                    <Route path={`${process.env.PUBLIC_URL + "/portfolio"}`} exact component={Portfolio}/>
                    <Route path={`${process.env.PUBLIC_URL + "/business-solution"}`} exact component={BusinessSolutions}/>
                    <Route path={`${process.env.PUBLIC_URL + "/business-process"}`} exact component={BusinessProcess}/>
                    <Route path={`${process.env.PUBLIC_URL + "/services"}`} exact component={Services}/>
                    <Route path={`${process.env.PUBLIC_URL + "/digital-services"}`} exact component={DigitalMarketing}/>
                    

                    <Route path={`${process.env.PUBLIC_URL + "/privacy-policy"}`} exact component={Privecy}/>
                    <Route path={`${process.env.PUBLIC_URL + "/terms-and-conditions"}`} exact component={TermsAndConditions}/>
                    <Route path={`${process.env.PUBLIC_URL + "/error"}`} exact component={Error}/>
                </Switch>
            </PageScrollTop>
        </Router>
    )
}


export default App
